const privacyList = [
  {
    title: '一、我们如何收集和使用您的个人信息',
    content: [
      {
        subtitle: '我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。对于敏感个人信息的收集，我们将通过加粗字体以提醒您的注意。',
        text: []
      },
      {
        subtitle: '1、您在使用我们服务时主动提供的信息',
        text: [
          '1）您在注册帐户时，为了帮您创建账户，所填写的信息,例如，您在注册时所留下的手机号码、微信、QQ、公众号的信息。',
          '2）您在开通产品免费试用权益时，为确保权益的发放、后续权益的提供、问题的解决与售后，所需填写的信息。例如，您在开通免费试用权益时所留下的姓名、手机号码、单位名称的信息。',
          '3）您在使用平台以代理服务商的身份帮用户提交小程序发布申请时填写的信息。例如，您在提交小程序，为确保审核通过及完成实名要求，在申请时留下的手机号码',
        ]
      },
      {
        subtitle: '2、我们在您使用平台服务时主动获取的信息',
        text: [
          '1）用户使用平台的日志统计信息。当您使用我们的平台服务时，我们可能会自动收集相关信息并存储为服务日志信息。例如，您操作平台所使用电脑的操作系统名称、操作系统版本、浏览器类型，以帮助分析产品运行的平台软件是否安全。',
        ]
      },
    ]
  },
  {
    title: '二、我们如何共享、转让、公开披露您的个人信息',
    content: [
      {
        subtitle: '1、基本规则',
        text: [
          '我们遵照法律法规的规定，对信息的分享进行严格限制，目前，我们不会主动共享或转让您的个人信息至第三方，如存在其他共享或转让您的个人信息或您需要我们将您的个人信息共享或转让至第三方情形时，我们会征得您对上述行为的明示同意，分享已经匿名化或去标识化处理后的信息，要求严格遵守我们关于数据隐私保护的措施与要求，包括但不限于根据数据保护协议、承诺书及相关数据处理政策进行处理，避免识别出个人身份，保障隐私安全。',
        ]
      },
      {
        subtitle: '2、转让',
        text: [
          '随着我们业务的持续发展，我们有可能进行合并、收购、资产转让等交易，我们将告知您相关情形，按照法律法规及不低于本《隐私保护指引》所要求的标准继续保护或要求接收者继续保护您的个人信息。',
        ]
      },
      {
        subtitle: '3、我们公开披露',
        text: [
          '我们不会对外公开披露或分享可用于识别您个人身份的信息（例如您的姓名或电子邮箱地址），如必须公开披露时，我们会向您告知此次公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明示同意。'
        ]
      },
      {
        subtitle: '4、共享、转让、公开披露个人信息时事先征得授权同意的例外',
        text: [
          '根据相关法律法规及国家标准，以下情形中，我们可能会在未事先征得您的授权同意的情况下共享、转让、公开披露个人信息：',
          '1）与我们履行法律法规规定的义务相关的；',
          '2）与国家安全、国防安全直接相关的；',
          '3）与公共安全、公共卫生、重大公共利益直接相关的；',
          '4）与犯罪侦查、起诉、审判和判决执行等直接相关的；',
          '5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；',
          '6）您自行向社会公众公开的个人信息；',
          '7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；',
        ]
      },
    ]
  },
  {
    title: '三、我们如何使用Cookie、标识符及相关技术',
    content: [
      {
        subtitle: 'Cookie 是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie 的内容只能由创建它的服务器检索或读取。每个 Cookie 对您的网络浏览器或移动应用程序都是唯一的。Cookie 通常包含标识符、站点名称以及一些号码和字符。',
        text: []
      },
      {
        subtitle: '在您浏览或使用腾讯WeMap（wemap.qq.com）网站时，我们有时会在计算机或移动设备上存储 Cookie，我们启用 Cookie 的目的在于改善用户体验，包括：',
        text: [
          '1）登录和验证。当您使用腾讯WeMap账号登录网站时，借助 Cookie，您可以直接从网站的一个页面跳转到另一个页面，而不必在每个页面上重新登录；同时创建key时可以获取验证码；也可以保证您的瓦片服务安全，防止其他人盗用。',
          '2）存储您的偏好和设置。网站能够借助 Cookie 来保存设置，如记录您是否看过网站使用指导、是否浏览过个性化样式使用指导等，方便用户下次访问同一个网站时不用再设置，提升用户体验。'
        ]
      },
      {
        subtitle: '腾讯WeMap网站（wemap.qq.com）不会将 Cookie 用于本声明所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。',
        text: [
          '如需详细了解如何更改部分常见浏览器的设置，请访问以下链接：',
          'IE浏览器：https://support.microsoft.com/zh-cn/help/17442/windows-internet-explorer-delete-manage-cookies',
          'Firefox浏览器：https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies',
          'Safari浏览器：https://support.apple.com/kb/PH19214?locale=zh_CN',
          'Opera浏览器：https://help.opera.com/en/latest/security-and-privacy/'
        ]
      },
    ]
  },
  {
    title: '四、变更',
    content: [
      {
        subtitle: '我们可能会适时对本《隐私保护指引》进行修订。当《隐私保护指引》的条款发生变更时，我们会在隐私协议中标注提示变更后的《隐私保护指引》，并向您说明生效日期。请您仔细阅读变更后的《隐私保护指引》内容，您继续使用WeMap产品表示您同意我们按照更新后的隐私保护指引处理您的个人信息。',
        text: []
      },
    ]
  },
  {
    title: '五、存储信息的地点和期限',
    content: [
      {
        subtitle: '1、存储信息的地点',
        text: ['我们遵守法律法规的规定，将境内收集的用户个人信息存储于中华人民共和国境内。如果您的个人信息存储地点从中国境内转移到境外的，我们将严格依照法律的规定执行。']
      },
      {
        subtitle: '2、存储信息的期限',
        text: [
          '一般而言，我们仅为实现目的所必需的最短时间保留您的个人信息。但在下列情况下，我们可能会更改个人信息的存储时间：',
          '1）为遵守适用的法律法规等有关规定；',
          '2）遵守法院判决、裁定或其他法律程序的规定；',
          '3）为遵守相关政府机关或法定授权组织的要求；',
          '4）我们有理由确信需要遵守法律法规等有关规定；',
          '5）为执行相关服务协议或本政策、维护社会公共利益，为保护您、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。',
        ]
      },
      {
        subtitle: '3、当我们的产品或服务发生停止运营的情形时，我们将采取例如推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息。        ',
        text: []
      },
    ]
  },
  {
    title: '六、信息安全保护',
    content: [
      {
        subtitle: '我们努力为用户的信息安全提供保障，以防止信息的丢失、不当使用、篡改、未经授权访问或披露。',
        text: [
          '1. 我们严格遵守法律法规保护用户的通信秘密。',
          '2. 我们通过不断提升的技术手段加强您在您的终端设备上访问并使用WeMap产品时信息传输的安全性，以防止您的个人信息泄露。例如：我们在存储您的个人信息时会通过AES进行加密，杜绝重要隐私明文的情况。',
          '3. 我们建立专门的管理制度、操作规程和组织以保障信息的安全。例如：我们严格限制访问信息的人员范围，要求他们遵守保密义务。',
          '4. 若发生个人信息泄露等安全事件，我们会启动应急预案及补救措施，阻止安全事件扩大，并依法履行相关的报告及通知义务。 ',
        ]
      },
      {
        subtitle: '我们会尽力保护您的个人信息。我们也请您理解，任何安全措施都无法做到无懈可击 。',
        text: []
      },
    ]
  },
  {
    title: '七、未成年人保护',
    content: [
      {
        subtitle: '我们非常重视对未成年人个人信息的保护。我们会根据相关法律法规的规定，要求不满十四周岁的未成年人在使用WeMap产品的服务或向我们提供个人信息前，应当事先取得自己的监护人（比如自己的父母）的授权同意；已满十四周岁未满十八周岁的未成年人，可以事先取得自己的监护人的授权同意或自行授权同意，并在监护人的指导下使用我们的WeMap产品。',
        text: []
      },
      {
        subtitle: '我们仅在法律法规允许、未成年人的监护人明确同意或者有必要保护未成年人的情况下使用、对外提供或公开披露该信息。',
        text: []
      },
      {
        subtitle: '若您是未成年人的监护人，请您关注您监护的未成年人是否是在取得您的授权同意之后使用WeMap产品的服务。如果您对您所监护的未成年人的个人信息有疑问，请通过第九节中的联系方式与我们联系。',
        text: []
      },
    ]
  },
  {
    title: '八、您的权利',
    content: [
      {
        subtitle: '在您使用我们服务的期间，为了让您更便捷地查询访问、删除、更正您的个人信息，同时保障您撤回个人信息使用的同意和注销账户的权利，您可参考下面的指引进行操作。此外，我们还设置了投诉渠道，您的意见将会得到及时的处理。',
        text: [
          '1.您可以在使用我们服务的过程中，通过控制台访问和修改您提供的注册信息和其他个人信息，若您需要删除您提供的注册信息和其他个人信息，或实现您对您个人信息的复制、撤回同意、注销账号等权利，请通过工单或本指引中的联系我们章节提供的方式和我们取得联系，并按照指引完成相关流程。您访问、修改、复制、撤回同意和删除个人信息的范围和方式将取决于您使用的具体服务。',
          '2.我们将按照本政策所述，仅为实现我们产品或服务的功能，收集、使用您的信息。 如您发现我们违反法律、行政法规的规定或者双方的约定收集、使用您的个人信息，您可以要求我们删除。如您发现我们收集、存储的您的个人信息有错误的，您也可以要求我们更正。您的任何与个人信息有关的权利，均可以通过本政策列明的联系方式与我们联系，以实现您访问、修改、复制、撤回同意、注销账号等权利。',
          '3.在您访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障帐号的安全。',
          '4.请您理解，由于技术所限、法律或监管要求，我们可能无法满足您的所有要求，我们会在收到您请求的15天内答复您的请求。'
        ]
      },
    ]
  },
  {
    title: '九、联系我们',
    content: [
      {
        subtitle: '我们设立了专门的个人信息保护团队和个人信息保护负责人，如果您对本隐私政策或个人信息保护相关事宜有任何疑问或投诉、建议时，您可以通过以下方式与我们联系：',
        text: [
          ' 1. 您可以填写个人信息保护和隐私问题反馈问卷与我们联系；',
          '移动端：https://privacy.qq.com/mb/questionnaire',
          'pc端：https://privacy.qq.com/questionnaire',
          '2. 将问题发送至Dataprivacy@tencent.com；',
          '3. 通过 https://kf.qq.com/ 与我们联系；',
          '4. 将您的问题邮寄至下列地址：',
          '中国广东省深圳市南山区海天二路33号腾讯滨海大厦 数据隐私保护部（收）',
          '邮编：518054',
          '我们将尽快审核所涉问题，并在收到您要求后的15日或法律法规规定的期限内予以反馈。'
        ]
      },
    ]
  },

];

export default privacyList;
