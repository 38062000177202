<template>
  <div class="card-list">
    <div v-for="(item, index) in list" :key="index" class="card-tag">
      <div class="card-title">
        <img :src="item.img" width="40px" height="40px" />
        {{ item.name }}
      </div>
      <div class="card-desc">
        {{ item.desc }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardList',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.card-list {
  width: @--content-width;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  .card-tag {
    width: 588px;
    height: 188px;
    margin-bottom: 24px;
    padding:0 40px;
    background-color: @--color-white;
    box-sizing: border-box;
    &:nth-child(odd){
      margin-right: 24px;
    }
    &:nth-last-child(1), &:nth-last-child(2){
      margin-bottom: 0px;
    }
  }
  .card-title {
    img{
      margin-right: 12px;
    }
    .para-text();
    font-weight: @--font-weight-link;
    margin-top: 30px;
    display: flex;
    align-items: center;
  }
  .card-desc {
    .base-text();
    color: @--color-tip;
    margin-top: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}
</style>
