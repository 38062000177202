<template>
  <div class="gallery-container">
    <div class="title-list-container">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="title-item"
        :class="{'is-active': active === index}"
        @mouseover="onHoverTab(index)"
      >
        {{item.name.toUpperCase()}}
      </div>
    </div>

    <div
      class="gallery-content"
    >
      <div class="intro-container" v-if="mode === 'product'">
        <div class="intro-content" v-for="(item, Index) in list" v-show="active === Index" :key="`text${Index}`">
          <div class="fade-in">
            <div class="intro-subtitle">WeMap {{item.name}}</div>
            <div class="intro-title">{{item.title}}</div>
            <div
              class="intro-text"
              v-for="(text, i) in item.desc"
              :key="i"
            >
              {{text}}
            </div>
          </div>
          <div class="intro-link" @click="onClickLink('click_home_product')">查看详情<i class="el-icon-right el-icon--right"></i></div>
        </div>
        <!-- <img :src="activeItem.img" class="intro-img" :id="`${_uid}-img`"> -->
        <img v-for="(item, Index) in list" v-show="active === Index" :key="`img${Index}`" :src="item.img" class="intro-img fade-in" />
      </div>
      <div class="intro-container solution-mode" v-else>
        <div class="intro-content" v-for="(item, Index) in list" v-show="active === Index" :key="`text${Index}`">
          <div class="fade-in">
            <div class="intro-title">{{item.name}}</div>
            <div class="intro-text">
              {{item.desc}}
            </div>
          </div>
          <div class="intro-link" @click="onClickLink('click_home_solution')">查看详情<i class="el-icon-right el-icon--right"></i></div>

        </div>
        <!-- <img :src="activeItem.img" class="intro-img" :id="`${_uid}-img`"> -->
        <img v-for="(item, imgIndex) in list" v-show="active === imgIndex" :key="imgIndex" :src="item.img" class="intro-img fade-in" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab-Gallery',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'product',
    }
  },
  data() {
    return {
      active: 0,
    };
  },
  watch: {
    // active() {
    //   const img = document.getElementById(`${this._uid}-img`);
    //   const text = document.getElementById(`${this._uid}-text`);
    //   // if (!img || !text) return;
    //   this.hideElement(img);
    //   this.hideElement(text);
    //   setTimeout(() => {
    //     this.showElement(img);
    //     this.showElement(text);
    //   }, 0);
    // }
  },
  computed: {
    activeItem() {
      return this.list[this.active];
    }
  },
  methods: {
    onHoverTab(index) {
      this.active = index;
    },
    onClickLink(name) {
      if (this.activeItem.link) {
        window.open(this.activeItem.link, '_blank');
        name && this.$beacon(name, { link: this.activeItem.link });
      }
    },
    // hideElement(dom) {
    //   if (!dom) return;
    //   dom.classList.remove('fade-in');
    //   dom.style.opacity = 0;
    // },
    // showElement(dom) {
    //   if (!dom) return;
    //   dom.classList.add('fade-in');
    //   dom.style.opacity = 1;
    // },
  },
};
</script>

<style lang="less" scoped>
.gallery-container {
  .fade-in {
    animation: fade-in .5s ease-in-out backwards;
  }
  .title-list-container {
    display: flex;
    justify-content: center;
    color: @--color-white;
    .title-item {
      width: 120px;
      text-align: center;
      height: 28px;
      font-size: @--font-size-medium;
      color: fade(@--color-white, 80%);
      line-height: 28px;
      padding: 8px 0;
      margin: 0 10px;
      cursor: default;
      z-index: 2;
    }
    .is-active {
      border-bottom: 2px solid @--color-white;
      font-weight: 500;
      color: inherit;
    }
  }
  .gallery-content {
    padding: 64px 0 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .background-image-center();
    .switch-icon {
      width: 56px;
      cursor: pointer;
      z-index: 2;
    }

    .intro-container {
      height: 440px;
      width: 1200px;
      margin: 0 32px;
      flex: none;
      display: flex;
      color: @--color-white;
      .intro-content {
        width: 480px;
        flex: none;
        background: linear-gradient(279deg, #295BFF 0%, #0582FF 100%);
        box-sizing: border-box;
        padding: 40px;
        position: relative;
        .intro-subtitle {
          .para-text();
          font-weight: 500;
          opacity: 0.8;
          margin-bottom: 4px
        }
        .intro-title {
          .sub-title-text();
          font-weight: 500;
          margin-bottom: 24px;
        }
        .intro-text {
          .base-text();
          letter-spacing: 0.02rem;
          opacity: 0.8;
          margin-bottom: 10px;
        }
        .intro-link {
          position: absolute;
          left: 40px;
          bottom: 48px;
          width: 136px;
          height: 44px;
          border: 1px solid fade(@--color-white, 50%);
          margin-top: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .base-text();
          border-radius: 4px;
        }
      }
      .intro-img {
        width: 720px;
      }
      // 解决方案
      &.solution-mode {
        position: relative;
        .intro-content {
          background-color: rgba(40, 55, 163, 0.8);
          position: relative;
          z-index: 2;
          // .intro-text {
          //   margin-bottom: 48px;
          // }
          .logo-container {
            position: absolute;
            left: 40px;
            bottom: 40px;
            font-size: 5px;
            .logo-img {
              width: 97px;
              margin-right: 16px;
            }
          }
        }
        .intro-img {
          .absolute-center();
          width: 1600px;
          height: 600px;
        }
      }
    }
  }
}
</style>
