<template>
  <div class="product-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Products',
};
</script>
<style lang="less" scoped>
.product-wrapper {
  min-width: @--content-width;
}
</style>
