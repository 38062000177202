export const lbsConsoleUrl = 'https://lbs.qq.com/dev/console/ticket/create?name=%E5%95%86%E5%8A%A1%E5%90%88%E4%BD%9C';

export const videoUrl = 'https://industry.map.qq.com/wemap/wemap-home/image/trial/trial_video.mp4';
export const posterUrl = 'https://industry.map.qq.com/wemap/wemap-home/image/trial/trial_poster.png';

// 行业类型
export const IndustryOptions = [
  { label: 'affairs', value: '政务' },
  { label: 'tourism', value: '文旅' },
  { label: 'traffic', value: '交通' },
  { label: 'travel', value: '出行' },
  { label: 'logistics', value: '物流' },
  { label: 'retail', value: '零售' },
  { label: 'energy', value: '能源' },
  { label: 'sports', value: '体育' },
  { label: 'agriculture', value: '农业' },
  { label: 'finance', value: '金融' },
  { label: 'campus', value: '校园' },
  { label: 'property', value: '地产' },
  { label: 'water', value: '水利' },
  { label: 'other', value: '其他' },
];
