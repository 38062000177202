<template>
  <div class="privacy support">
    <div class="support-banner">隐私协议</div>
    <div class="support-main">
      <h2 class="privacy-title">WeMap腾讯地图产业版隐私协议</h2>
      <p class="privacy-border"></p>
      <p class="privacy-welcome text-indent-2">
        欢迎使用由深圳市腾讯计算机系统有限公司提供的WeMap腾讯地图产业版的产品和服务！
      </p>
      <p class="privacy-intro text-indent-2">
        <span>WeMap腾讯地图产业版严格遵守法律法规，遵循以下隐私保护原则，为您提供更加安全、可靠的服务：</span>
        <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. 安全可靠：我们竭尽全力通过合理有效的信息安全技术及管理流程，防止您的信息泄露、损毁、丢失。
        <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. 自主选择：我们为您提供便利的信息管理选项，以便您做出合适的选择，管理您的个人信息。
        <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. 保护通信秘密：我们严格遵照法律法规，保护您的通信秘密，为您提供安全的通信服务。
        <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. 合理必要：为了向您和其他用户提供更好的服务，我们仅收集必要的信息。
        <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. 清晰透明：我们努力使用简明易懂的表述，向您介绍隐私政策，以便您清晰地了解我们的信息处理方式。
        <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. 将隐私保护融入产品设计：我们严格遵照法律法规，保护您的通信秘密，为您提供安全的通信服务。
      </p>
      <p class="privacy-intro text-indent-2">
          <span>希望您仔细阅读《隐私政策》（以下简称“本政策”），详细了解我们对信息的收集、使用方式，以便您更好地了解我们的服务并作出适当的选择。如果您对本隐私保护内容有任何疑问和建议，您可以通过本《隐私保护指引》中“联系我们”中公布的联系方式与我们联系。</span>
      </p>
      <ul class="privacy-atricle">
        <li class="privacy-atricle-item" v-for="(privacy, index) in privacyList" :key="index">
          <h3 class="privacy-atricle-title text-indent-2">{{ privacy.title }}</h3>
          <article v-for="(article, i) in privacy.content" :key="i">
            <h4 class="privacy-atricle-subtitle text-indent-2" v-html="article.subtitle"></h4>
            <p class="privacy-atricle-text text-indent-2" v-for="(text, key) in article.text" :key="key" v-html="text"></p>
          </article>
        </li>
      </ul>
      <div class="support-footer">深圳市腾讯计算机系统有限公司</div>
    </div>
  </div>
</template>

<script>
import privacyList from './privacyList';
export default {
  data() {
    return {
      privacyList
    };
  },
};
</script>

<style lang="less" scoped>
@import '../index.less';

.support-banner {
  background-image: url("https://industry.map.qq.com/wemap/wemap-home/image/support/clauseBanner.png"), linear-gradient(89deg, #0B0E2E 0%, #0D1041 100%);
}

.privacy {
  min-width: 1200px;
  padding-bottom: 190px;
  text-align: justify;

  &::v-deep span {
    font-weight: @--font-weight-link;
    color: @--color-text;
  }

  &-title {
    .support-title();
    text-align: center;
  }

  .support-main {
    padding: 60px;
  }

  &-border {
    height: 2px;
    background: @--color-border;
    margin: 32px 0;
  }

  &-welcome {
    .support-text();
  }

  &-intro {
    .support-text();
  }

  &-atricle {
    &-title {
      margin: 24px 0;
    }

    &-subtitle {
      .support-text();
    }

    &-text {
      .support-text();

      &::v-deep a {
        color: #0062ff;
      }
    }
  }
  .support-footer {
    text-align: right;
    margin-top: 32px;
    font-weight: 500;
    color: #10131F;
  }
}
</style>
