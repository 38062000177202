<template>
  <div class="clause support">
    <div class="support-banner">服务协议</div>
    <div class="support-main">
      <h2 class="clause-title">WeMap腾讯地图产业版服务协议</h2>
      <p class="clause-border"></p>
      <p class="clause-welcome text-indent-2">
        欢迎您使用WeMap腾讯地图产业版服务！
      </p>
      <p class="clause-intro text-indent-2">
        <span>
          为使用WeMap腾讯地图产业版（以下简称“WeMap”）服务，您应当阅读并遵守《WeMap腾讯地图产业版服务协议》（以下简称“本协议”），《腾讯位置服务开放API服务协议》、《腾讯位置服务隐私协议》、《QQ软件许可及服务协议》、《腾讯微信软件许可及服务协议》
          等相关协议及业务规则。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，对用户权限进行限制的条款、约定争议解决方式和司法管辖的条款（如第十四条相关约定）等，以及开通或使用某项服务的单独协议，并选择接受或不接受。
          限制、免责条款或其他涉及您重大权益的条款可能以黑体加粗形式提示您注意。
        </span>
      </p>
      <p class="clause-intro text-indent-2">
        <span>
          除非您已阅读并接受本协议所有条款，否则您请不要注册、登陆或使用本服务。一旦您“同意本协议并提交”（具体措辞详见注册页面）并完成申请注册流程、或您以任何方式使用本服务行为，即视为您已阅读并同意本协议的约束。
        </span>
      </p>
      <p class="clause-intro text-indent-2">
        您有违反本协议的任何行为时，腾讯有权依照违反情况，随时单方限制、中止或终止向您提供本服务，并有权追究您的相关责任。
      </p>
      <ul class="clause-atricle">
        <li class="clause-atricle-item" v-for="(clause, index) in clauseList" :key="index">
          <h3 class="clause-atricle-title text-indent-2">{{ clause.title }}</h3>
          <article v-for="(article, i) in clause.content" :key="i">
            <h4 class="clause-atricle-subtitle text-indent-2" v-html="article.subtitle"></h4>
            <p class="clause-atricle-text text-indent-2" v-for="(text, key) in article.text" :key="key" v-html="text"></p>
          </article>
        </li>
      </ul>
      <div class="support-footer">深圳市腾讯计算机系统有限公司</div>
    </div>
  </div>
</template>

<script>
import clauseList from './clauseList';
export default {
  data() {
    return {
      clauseList
    };
  },
};
</script>

<style lang="less" scoped>
@import '../index.less';

.support-banner {
  background-image: url("https://industry.map.qq.com/wemap/wemap-home/image/support/clauseBanner.png"), linear-gradient(89deg, #0B0E2E 0%, #0D1041 100%);
}

.clause {
  min-width: 1200px;
  padding-bottom: 190px;
  text-align: justify;

  &::v-deep span {
    font-weight: @--font-weight-link;
    color: @--color-text;
  }

  &-title {
    .support-title();
    text-align: center;
  }

  .support-main {
    padding: 60px;
  }

  &-border {
    height: 2px;
    background: @--color-border;
    margin: 32px 0;
  }

  &-welcome {
    .support-text();
  }

  &-intro {
    .support-text();
  }

  &-atricle {
    &-title {
      margin: 24px 0;
    }

    &-subtitle {
      .support-text();
    }

    &-text {
      .support-text();

      &::v-deep a {
        color: #0062ff;
      }
    }
  }
  .support-footer {
    text-align: right;
    margin-top: 32px;
    font-weight: 500;
    color: #10131F;
  }
}
</style>
