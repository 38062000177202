<template>
  <div class="coop-case">
    <div class="area-title">合作案例展示</div>
    <div class="coop-case-tab">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="coop-case-tabname"
        :class="{ 'is-active': active === index }"
        @mouseenter="onHoverTab(index)"
      >
        {{ item.name.toUpperCase() }}
      </div>
    </div>
    <div class="coop-case-container" :style="{ height: activeItem.height }">
      <div class="coop-case-content" :id="`${_uid}-text`">
        <div class="coop-case-title">{{ activeItem.title }}</div>
        <div
          class="coop-case-desc"
          v-for="(item, index) in activeItem.desc"
          :key="`desc${index}`"
        >
          <div class="coop-case-texttitle" v-if="item.title">
            <img class="coop-case-texticon" :src="item.icon" />
            {{ item.title }}
          </div>
          <div
            class="coop-case-textcontent"
            v-for="(content, index) in item.content"
            :key="`content${index}`"
          >
            {{ content }}
          </div>
        </div>
      </div>
      <div v-for="(item, index) in list" :key="`img${index}`">
        <img
          v-if="item.img"
          :src="item.img"
          class="coop-case-img"
          :class="{ 'fade-out': active === index, 'fade-in': active !== index }"
        />
      </div>
      <div v-for="(item, index) in list" :key="`video${index}`">
        <video
          v-if="item.video"
          :poster="item.poster"
          :src="item.video"
          class="coop-case-img"
          :class="{ 'fade-out': active === index, 'fade-in': active !== index }"
          webkit-playsinline="true"
          preload="metadata"
          playsinline="true"
          muted
          autoplay
          loop
          type="video/mp4"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoopCase',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  watch: {
    active() {
      const text = document.getElementById(`${this._uid}-text`);
      if (!text) return;
      this.hideElement(text);
      setTimeout(() => {
        this.showElement(text);
      }, 0);
    },
  },
  computed: {
    activeItem() {
      return this.list[this.active];
    },
  },
  methods: {
    onHoverTab(index) {
      this.active = index;
    },
    hideElement(dom) {
      if (!dom) return;
      dom.classList.remove('fade-in');
      dom.style.opacity = 0;
    },
    showElement(dom) {
      if (!dom) return;
      dom.classList.add('fade-in');
      dom.style.opacity = 1;
    },
  },
};
</script>

<style lang="less" scoped>
.coop-case {
  .fade-effect();
  .sub-template();
  background-color: @--color-light;
  .coop-case-tab {
    display: flex;
    justify-content: center;
    color: @--color-text;
    min-width: @--content-width;
    margin-bottom: 48px;
    .coop-case-tabname {
      .para-text();
      color: @--color-subtext;
      height: 28px;
      padding: 8px 20px;
      margin: 0px 18px;
      cursor: default;
    }
    .is-active {
      border-bottom: 2px solid @--color-text;
      font-weight: @--font-weight-bold;
      color: inherit;
    }
  }
  .coop-case-container {
    width: @--content-width;
    color: @--color-text;
    text-align: left;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    .coop-case-content {
      width: 500px;
      box-sizing: border-box;
      margin: auto 40px auto 0px;
      .coop-case-title {
        .para-text();
        font-weight: @--font-weight-bold;
        margin-bottom: 24px;
      }
      .coop-case-desc {
        color: @--color-tip;
        .coop-case-texttitle {
          .base-text();
          color: @--color-text;
          margin-bottom: 12px;
          display: flex;
          .coop-case-texticon {
            width: 16px;
            height: 16px;
            margin: auto 4px auto 0px;
          }
        }
        .coop-case-textcontent {
          .intro-text();
          color: @--color-tip;
          width: 500px;
          text-align: justify;
          &:nth-last-child(1) {
            margin-bottom: 20px;
          }
        }
      }
    }
    .coop-case-img {
      border-radius: 8px;
      width: 660px;
      position: absolute;
      left: 540px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
