<template>
  <div class="gallery-container">
    <div class="title-list-container" :class="[`${mode}-title-container`]">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="title-item"
        :class="[{'is-active': active === index}, `${mode}-title`]"
        @click="onClickTab(index)"
      >
        {{item.name.toUpperCase()}}
      </div>
    </div>

    <div class="gallery-content">
      <div class="intro-container" v-if="mode === 'product'">
        <div class="intro-content">
          <swiper ref="mobileSwiper" :options="swiperOptions">
            <swiper-slide
              v-for="(item, index) in list"
              :key="index"
            >
              <img :src="item.img" class="intro-img">
              <div class="intro-title">WeMap {{item.name}} {{item.title}}</div>
              <div
                class="intro-text"
                v-for="(text, i) in item.desc"
                :key="i"
              >
                {{text}}
              </div>
              <div class="mobile-intro-link" @click="onClickLink(item.link)">查看详情<i class="el-icon-right el-icon--right"></i></div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="intro-container solution-mode" v-else>
        <div class="intro-content">
          <swiper ref="mobileSwiper" :options="swiperOptions">
            <swiper-slide
              v-for="(item, index) in list"
              :key="index"
            >
              <img :src="item.img" class="intro-img">
              <div class="intro-text">
                {{item.desc}}
              </div>
              <div class="mobile-intro-link" @click="onClickLink(item.link)">查看详情<i class="el-icon-right el-icon--right"></i></div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { scrollIntoViewHorizon } from '@/utils/index';

export default {
  name: 'Mobile-Gallery',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'product',
    }
  },
  data() {
    const ctx = this;
    return {
      active: 0,
      swiperOptions: {
        loop: true,
        // effect: 'fade',
        // fadeEffect: {
        //   crossFade: true,
        // },
        on: {
          slideChange() {
            if (this.activeIndex > ctx.list.length) {
              ctx.active = 0;
            } else if (this.activeIndex === 0) {
              ctx.active = ctx.list.length - 1;
            } else {
              ctx.active = this.activeIndex - 1;
            }

            if (!this._isTriggerByClick) {
              const activeTitle = document.getElementsByClassName(`${ctx.mode}-title title-item`)[ctx.active];
              // console.log('activeTitle: ', activeTitle);
              const [titleContainer] = document.getElementsByClassName(`${ctx.mode}-title-container`);
              scrollIntoViewHorizon(titleContainer, activeTitle);
            }
            this._isTriggerByClick = false;
          },
        }
      },
    };
  },
  computed: {
    // activeItem() {
    //   return this.list[this.active];
    // },
    swiper() {
      return this.$refs.mobileSwiper.swiper;
    }
  },
  methods: {
    onClickTab(index) {
      this.active = index;
      this.swiper._isTriggerByClick = true;
      this.swiper.slideTo(index + 1);
    },
    onClickLink(link) {
      // this.$router.push({
      //   path: link
      // });
      window.location.href = link;
    }
  },
};
</script>

<style lang="less" scoped>
.gallery-container {
  .mobile-intro-link {
    margin: 0 auto;
    width: 212px;
    height: 72px;
    border: 1px solid fade(@--color-white, 50%);
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .base-text();
    font-size: 28px;
    border-radius: 4px;
  }
  color: @--color-white;
  .title-list-container {
    display: flex;
    overflow-x: auto;
    padding: 0 40px;
    .title-item {
      flex: none;
      .sub-title-text();
      color: fade(@--color-white, 60%);
      padding: 20px 24px;
      margin-right: 24px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .is-active {
      border-bottom: 2px solid @--color-white;
      font-weight: 500;
      color: inherit;
    }
  }
  .gallery-content {
    .intro-container {
      padding: 0 40px;
      .intro-content {
        overflow: hidden;
        .intro-img {
          width: 670px;
          height: 372px;
        }
        .intro-title {
          font-size: 32px;
          line-height: 40px;
          height: 40px;
          font-weight: 500;
          margin-top: 56px;
          margin-bottom: 40px;
          text-align: center;
        }
        .intro-text {
          font-size: 28px;
          line-height: 40px;
          opacity: 0.8;
          margin-bottom: 16px;
        }
      }
      // 解决方案
      &.solution-mode {
        padding: 0;
        .intro-content {
          margin-top: 20px;
          background-color: #0A0E3E;
          .intro-text {
            margin: 50px 40px 48px;
            line-height: 44px;
          }
          .intro-img {
            width: 750px;
            height: 600px;
          }
          // .logo-container {
          //   padding: 0 40px;
          //   .logo-img {
          //     width: 194px;
          //     margin-right: 28px;
          //   }
          // }
        }
      }
    }
  }
}
</style>
