<template>
  <div>
    <banner
      title="智慧交通解决方案"
      subtitle="基于WeMap对人、车、路、地、物的时空感知、数据管理、智能分析和可视化能力，构建交通业务实时态势感知、历史变化溯源、时空模拟推演的全流程解决方案，从而解决交通行业在规划、建设、管理、运营和服务方面对空间位置服务需求的问题。"
      backgroundImg="https://industry.map.qq.com/wemap/wemap-home/image/solution/transport/banner.png"
      backgroundImgStyle="linear-gradient(88deg, #060A31 0%, #020822 100%)"
    />
    <application-scene :list="sceneList"></application-scene>
    <div class="animation-wrapper">
      <product-service :list="serviceList"></product-service>
      <div class="solution-page-products">
        <div class="area-title">产品架构</div>
        <img
          src="https://industry.map.qq.com/wemap/wemap-home/image/solution/transport/framework.jpg"
          width="1200px"
        />
      </div>
      <coop-case :list="caseList"></coop-case>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner';
import ApplicationScene from '../components/application-scene';
import ProductService from '../components/product-service';
import CoopCase from '../components/coop-case';
import BackgroundIcon from '../assets/icon/icon_background.png';
import FeatureIcon from '../assets/icon/icon_feature.png';
import ValueIcon from '../assets/icon/icon_value.png';
import { animation } from '@/utils';

export default {
  name: 'SolutionTransport',
  components: {
    Banner,
    ApplicationScene,
    ProductService,
    CoopCase,
  },
  data() {
    return {
      sceneList: [
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/transport/scene1.jpg', name: '智能网联' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/transport/scene2.jpg', name: '智慧高速' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/transport/scene3.jpg', name: '智慧物流' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/transport/scene4.jpg', name: '智慧交管' },
      ],
      serviceList: [
        {
          name: '智能网联一张图',
          content: '网联一张图是腾讯基于在地图领域多年积累、结合多个项目实战经验，打造的一款与智能网联业务的地图应用产品。该产品由WeMap产品组件、LBS位置大数据、其他网联图产品服务构成，满足网联高精地图数据采集制作、感知设备设施标定、车路云地图信息互通以及网联业务三端应用构建（业务桌面、展示大屏、移动终端）。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/transport/service1.jpg',
          height: '436px',
        },
        {
          name: '交通态势感知平台',
          content: '基于WeMap强大的空间数据管理能力将用户的业务数据、资产数据、物联网设备、监控卡口设备上图，实现人、车、物、事件、业务基于电子围栏进行精细化管理，基于二三维地图进行可视化展现，基于精准位置信息进行科学性分析，应用于交通指挥、交通设施的运维、交通路政日常管理、综合缓堵保畅整治、交通安全等方面。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/transport/service2.jpg',
          height: '436px',
        },
        {
          name: '交通位置大数据',
          content: '通过海量交通数据的分析和建模，掌握城市内和城市间人群动态流动特征，进而对城市交通枢纽规划、轨道交通规划、公交线路规划、航司线路调配等量化评估，达到辅助交通科学规划决策，提升城市资源有效运用的目标。面向智慧服务区、智慧枢纽、智慧管养、智慧停车、航司营运等领域。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/transport/service3.jpg',
          height: '436px',
        },
      ],
      caseList: [
        {
          name: '成都龙泉驿智能网联',
          title: '成都龙泉驿智能网联',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['成都龙泉驿智能网联项目中，腾讯地图围绕大运会应用场景、城市交通治理需求，提供高精地图数据采集生产、高精标精一体化地图渲染引擎、高精定位服务，赋能城市交通智慧化建设。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['实现标精、高精一体化地图引擎，支持高精道路、城市背景模型三维重建，支持交通信号灯、车辆实时轨迹动态接入。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['为项目提供数字底座建设，支持各系统开发厂商在地图引擎基础上开发云控平台、车路协同平台、全息路口、孪生大屏、MaaS等行业应用。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/transport/case2.jpg',
          height: '400px',
        },
        {
          name: '西青车路协同项目',
          title: '西青车路协同项目',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['天津(西青)车联网先导区建设车联网先导应用综合服务平台和基础设施环境支撑产业服务平台，促进场景应用、建立运营管理机制，完善基础设施环境。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['实现一张图管理多种车辆管理和可视化展现，通过位置大数据实现重点区域人流的分钟级监测、工作/居住人口出行便捷性的评估。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['有助于对于重点关注车辆的科学管理，辅助城市交通道路等交通枢纽的运力需求判断，有助于城市交通规划的科学决策。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/transport/case1.jpg',
          height: '400px',
        },
      ],
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.solution-page-products {
  .sub-template();
}
.area-title {
  .sub-title();
}
</style>
