<template>
  <div class="product-mobile-container">
    <banner
      title="WeMap Mobile"
      subtitle="安全、精准、个性化的移动端地图导航服务"
      background-img="https://industry.map.qq.com/wemap/wemap-home/image/product/mobile/banner.png"
    />
    <advantage-list :list="advantageList"></advantage-list>
    <div class="animation-wrapper">
      <div class="ability-container dark-mode">
        <div class="container-title">我们的能力</div>
        <ability-list :info="locationInfo" :list="locationList" />
      </div>

      <div class="ability-container">
        <ability-list
          :info="trailInfo"
          :list="trailList"
          theme="light"
          align="right"
        />
      </div>

      <div class="ability-container dark-mode">
        <ability-list :info="trafficInfo" :list="trafficList" />
      </div>

      <div class="ability-container">
        <ability-list :info="plotInfo" theme="light" align="right" />
      </div>

      <div class="ability-container dark-mode">
        <ability-list :info="mapInfo" :list="mapList" />
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '../components/product-banner';
import AdvantageList from '../components/advantages-list';
import AbilityList from '../components/ability-list';
import { animation } from '@/utils';

export default {
  name: 'ProductMobile',
  components: {
    Banner,
    AdvantageList,
    AbilityList,
  },
  data() {
    return {
      advantageList: [
        {
          name: '完整的导航方案',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/mobile/advantage1.png',
        },
        {
          name: '顺畅的导航体验',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/mobile/advantage2.png',
        },
        {
          name: '便捷的开发模式',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/mobile/advantage3.png',
        },
      ],
      locationInfo: {
        title: '地图展示',
        desc: '提供标准地图、卫星图、路况图的加载能力。标准图支持展示精细的面区域、道路、建筑物及重要的POI信息，支持2D、3D两种地图形式。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/mobile/location.png',
      },

      trailInfo: {
        title: '数据图层及覆盖物',
        desc: '支持绘制各种类型覆盖物，如：点标注、折线和区域面。支持蜂窝图、热力图、弧线图、轨迹图、散点图进行数据可视化展示。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/mobile/trail.png',
      },

      trafficInfo: {
        title: '地图服务',
        desc: '支持移动端的地点搜索、关键词输入提示、行政区划、沿途搜索、地址解析、逆地址解析、路线规划等服务访问。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/mobile/traffic.png',
      },

      plotInfo: {
        title: '定位与路线规划',
        desc: '支持根据当前定位点到指定目的地计算符合驾车场景的路线方案。提供时间最短和少收费两种方案。支持设置途经点，为用户提供更符合其需求的驾车路线。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/mobile/plot.png',
      },

      mapInfo: {
        title: '导航地图',
      },
      mapList: [
        {
          name: '实时导航和模拟导航',
          tip: '支持真实场景下的实时导航，以及按规划路线进行导航线路播放的模拟导航。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/mobile/map1.png',
        },
        {
          name: '诱导播报',
          tip: '将导航和行驶信息以集成的语音合成接口转成声音信息，完成导航诱导播报。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/mobile/map2.png',
        },
        {
          name: '导航设置',
          tip: '支持对起终点的Marker图标、导航路线、自车点、自车道罗盘等导航的控件进行自定义样式，并控制定位的开启和关闭。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/mobile/map3.png',
        },
      ],
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.product-mobile-container {
  .ability-container {
    overflow: hidden;
    &.dark-mode {
      color: @--color-text;
      background: #f5f7fc;
    }
    &.light-mode {
      color: @--color-text;
      background-image: linear-gradient(
        90deg,
        #ffffff 0%,
        #a1a1a1 33%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    .container-title {
      .title-text();
      font-family: @--font-tencent;
      text-align: center;
      margin-top: 80px;
    }
  }
}
</style>
