<template>
  <div class="product-builder-container">
    <banner title="WeMap Builder" subtitle="多场景地图应用快速搭建" background-img="https://industry.map.qq.com/wemap/wemap-home/image/product/builder/banner.png" />
    <advantage-list :list="advantageList"></advantage-list>
    <div class="animation-wrapper">
      <div class="ability-container dark-mode">
        <div class="container-title">我们的能力</div>
        <ability-list
          :info="mapAppInfo"
        />
      </div>

      <div class="ability-container">
        <ability-list
          :info="abilityInfo"
          theme="light"
          align="right"
        />
      </div>

      <div class="ability-container dark-mode">
        <ability-list
          :info="spaceInfo"
        />
      </div>

      <div class="ability-container">
        <ability-list
          :info="multiterminalInfo"
          theme="light"
          align="right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '../components/product-banner';
import AdvantageList from '../components/advantages-list';
import AbilityList from '../components/ability-list';

import { animation } from '@/utils';

export default {
  name: 'ProductData',
  components: {
    Banner,
    AdvantageList,
    AbilityList,
  },
  data() {
    return {
      advantageList: [
        { name: '拖拽式应用搭建', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/builder/advantage1.png' },
        { name: '丰富的地图组件', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/builder/advantage2.png' },
        { name: '多端应用一键发布', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/builder/advantage3.png' },
      ],
      mapAppInfo: {
        title: '地图应用高效搭建',
        desc: '提供自定义和模版化两种地图应用搭建方式。',
        detail: [
          { subtitle: '自定义搭建', content: '自定义搭建支持从零开始搭建页面，通过对按钮、图片、地图等组件拖拽的方式搭建应用系统，适用于定制化要求较高的场景。' },
          { subtitle: '模版化搭建', content: '模版化搭建支持通过选择平台内置行业模版，基于模版框架进行布局和样式调整，实现应用快速发布。' },
        ],
        poster: 'https://industry.map.qq.com/wemap/wemap-home/image/product/builder/ability-img1.jpg',
        video: 'https://industry.map.qq.com/wemap/wemap-home/image/product/builder/ability-video1.mp4',
      },
      abilityInfo: {
        title: '多种地图能力灵活配置',
        desc: '集成多种地图能力，快速便捷配置地图属性。提供多种地图样式风格，搭配热力图层、蜂窝图层、迁移图层等，更直观、更高效的发布地图应用。',
        poster: 'https://industry.map.qq.com/wemap/wemap-home/image/product/builder/ability-img2.jpg',
        video: 'https://industry.map.qq.com/wemap/wemap-home/image/product/builder/ability-video2.mp4',

      },
      spaceInfo: {
        title: '空间数据快速上图',
        detail: [
          { subtitle: '', content: '支持业务数据的导入、存储、编辑和管理，可一键关联到组件，快速实现数据上图。' },
          { subtitle: '', content: '支持WeMap Base已发布服务的快速接入，如WMS/WMST/WFS/MVT等格式数据。' },
        ],
        poster: 'https://industry.map.qq.com/wemap/wemap-home/image/product/builder/ability-img3.jpg',
        video: 'https://industry.map.qq.com/wemap/wemap-home/image/product/builder/ability-video3.mp4',

      },
      multiterminalInfo: {
        title: '多端应用快速发布及源码级二次开发',
        desc: '支持创建PC、H5、小程序类型的应用，同时支持公网和专网两种发布方式。提供应用源码下载，实现对应用二次开发以满足客户的多样化定制需求。',
        poster: 'https://industry.map.qq.com/wemap/wemap-home/image/product/builder/ability-img4.jpg',
        video: 'https://industry.map.qq.com/wemap/wemap-home/image/product/builder/ability-video4.mp4',

      },
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.product-builder-container {
  .ability-container {
    overflow: hidden;
    &.dark-mode {
      color: @--color-text;
      background: #F5F7FC;
    }
    &.light-mode {
      color: @--color-text;
      background-image: linear-gradient(90deg, #FFFFFF 0%, #A1A1A1 33%, rgba(0, 0, 0, 0) 100%);
    }
    .container-title {
      .title-text();
      font-family: @--font-tencent;
      text-align: center;
      margin-top: 80px;
    }
  }
}
</style>
