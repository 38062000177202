<template>
  <div class="product-vis-container">
    <div class="banner-container">
      <div class="banner-content">
        <div class="content-area">
          <h1 class="banner-title">WeMap Vis</h1>
          <p class="banner-text">高品质二三维一体化渲染</p>
          <button class="banner-btn" @click="onClickVideo"><img src="https://industry.map.qq.com/wemap/wemap-home/image/index/vedio_play@2x.png"/>视频演示</button>
        </div>
        <video
          class="banner-video"
          webkit-playsinline="true"
          preload="metadata"
          playsinline="true"
          poster="https://industry.map.qq.com/wemap/wemap-home/image/product/vis/banner.jpg"
          src="https://industry.map.qq.com/wemap/wemap-home/image/product/vis/vis-banner.mp4"
          muted="muted"
          autoplay="autoplay"
          loop="loop"
          type="video/mp4"
          width="2400px"
          height="520px"
        >
        </video>
    </div>
    </div>
    <video-dialog :visible.sync="isShowVideo" :video="video"></video-dialog>
    <advantage-list :list="advantageList"></advantage-list>
    <div class="animation-wrapper">
      <div class="ability-container  gray-mode">
        <div class="container-title">我们的能力</div>
          <ability-list
            v-for="(item, index) in ablilityInfo"
            :key="index"
            :info="item"
            :theme="item.theme"
            :align="item.align"
          />
      </div>
      <div class="ability-container structure-chart">
        <div class="container-title">统一的开放体系</div>
        <div class="system-tip">基于游戏引擎和轻量化Web引擎，以统一的 B/S 架构进行API开放，通过发挥不同渲染引擎的优势，降低接入门槛，实现不同效果的无缝切换</div>
        <img class="system-img" src="https://industry.map.qq.com/wemap/wemap-home/image/product/vis/system.png" />
      </div>
    </div>
  </div>
</template>

<script>
import AdvantageList from '../components/advantages-list';
import AbilityList from '../components/ability-list';
import VideoDialog from '@/components/video-dialog';
import { animation } from '@/utils';

export default {
  name: 'Product',
  components: {
    AdvantageList,
    AbilityList,
    VideoDialog
  },
  data() {
    return {
      isShowVideo: false,
      video: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/vis-video.mp4',
      advantageList: [
        { name: '实时的三维化渲染', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/advantage1.png' },
        { name: '流畅的性能体验', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/advantage2.png' },
        { name: '统一的开放体系', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/advantage3.png' },
      ],
      ablilityInfo: [
        {
          title: '实时三维化渲染',
          desc: '依托腾讯自有地图数据体系，可自动化生成城市三维模型，实现从宏观到微观的一体化呈现。',
          poster: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/vis-img1.jpg',
          video: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/vis-video1.mp4',
          align: 'left',
          theme: 'light',
        },
        {
          title: '精细化三维模型融合',
          desc: '将标准化处理后的三维数据成果，通过三维图层的方式实现一体化融合，支持倾斜摄影、 手工模型、点云和 BIM 等数据类型。',
          poster: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/vis-img2.png',
          video: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/vis-video2.mp4',
          align: 'right',
          theme: 'light',
        },
        {
          title: '丰富的可视化组件',
          desc: '提供散点图、热力图、迁徙图、轨迹线、水晶体等丰富的数据可视化图层组件。',
          poster: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/vis-img3.png',
          video: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/vis-video3.mp4',
          align: 'left',
          theme: 'light',
        },
        {
          title: '高仿真环境渲染',
          desc: '利用腾讯自有地图数据优势提取城市关键要素，通过自建丰富材质库形成对建筑物、道路、绿地水系等要素的美化，形成高仿真城市渲染效果，并支持日夜、四季等三维城市环境渲染。',
          poster: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/vis-img4.jpg',
          video: 'https://industry.map.qq.com/wemap/wemap-home/image/product/vis/vis-video4.mp4',
          align: 'right',
          theme: 'light',
        },
      ],
    };
  },
  methods: {
    onClickVideo() {
      this.isShowVideo = true;
      const dom = document.getElementById('videoPlayer');
      if (dom) {
        dom.play();
      }
    }
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.product-vis-container {
  .el-dialog__wrapper {
    z-index: 999999 !important;
  }
  .banner-container {
    height: 520px;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
    // background-image: linear-gradient(270deg, #0C0F41 0%, #080B36 100%);
    background-color: #010008;
    z-index: 1;
    overflow: hidden;

      .banner-content {
        height: 100%;
        background-size: 2400px 520px;

        .content-area {
          width: @--content-width;
          margin: 0 auto;

          .banner-title {
            .title-banner();
            height: 56px;
            font-family: @--font-tencent;
            color: @--color-white;
            z-index:10;

            &:first-of-type {
              padding-top: 210px;
            }
          }

          .banner-text {
            .base-text();
            color: fade(@--color-white, 60%);
            height: 24px;
            margin-top: 16px;
            z-index:10;
          }

          .banner-btn {
            margin-top: 48px;
            .button-primary();

            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
          }
        }

        .banner-video {
          .absolute-center();
          z-index: -1;
        }
      }
  }
  .structure-chart {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .ability-container {
    overflow: hidden;
    color: @--color-text;
    text-align: left;
    background-color: @--color-light;
    .ability-list:nth-child(odd) {
      background-color: @--color-white;
    }
    .container-title {
      .title-text();
      font-family: @--font-tencent;
      text-align: center;
      margin-top: 80px;
    }
    .system-tip {
      .base-text();
      color: @--color-subtext;
      margin-top: 24px;
      margin-bottom: 56px;
      text-align: center;
    }
    .system-img{
      display: block;
      width: 1200px;
      margin: 0px auto 60px auto;
    }
  }
}
</style>
