<template>
  <div class="trial">
    <div class="left">
      <div id="container" style="width: 100%; height: 100%"></div>
    </div>
    <div class="right">
      <div class="content-wrapper">
        <div class="title">WeMap地图产业版</div>
        <div class="sub-title">帮您实现从数据接入、管理、分析、发布、应用的数据全系处理
          服务，并可在地图上快速应用</div>
        <div class="desc">
          <div v-if="canTrial">您当前不具备WeMap地图产业版的使用权益，新用户可享15天免费试用，您也可以
            直接联系商务进行产品采购。</div>
          <div v-else>您于<span class="time">{{ dateTime }}</span>已开通过WeMap产品15天免费试用权益，如需继续使用可直接联
            系商务进行产品采购。</div>
        </div>
        <div class="btn-wrapper">
          <button class="experience-btn" :class="{ 'disabled': !canTrial }" @click="showDialog">
            15天体验
          </button>
          <button class="purchase-btn">
            <a class="link" target="_blank" :href="`${lbsConsoleUrl}`">产品采购</a>
          </button>

        </div>
      </div>
    </div>

    <!-- 开通试用表单弹框 -->
    <el-dialog title="开通试用" :visible="dialogVisible" @close="handleClose">
      <el-form label-position="top" :model="form" ref="form" :rules="rules" label-width="80px" class="form-wrapper">
        <el-form-item label="单位名称" prop="companyName">
          <el-input class="form-input" v-model="form.companyName" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="所属行业" prop="industry">
          <el-select v-model="form.industry" placeholder="请选择行业" clearable>
            <el-option v-for="item in industryOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人" prop="contactPerson">
          <el-input class="form-input" v-model="form.contactPerson" placeholder="请输入联系人姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contactNumber">
          <el-input class="form-input" v-model="form.contactNumber" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="需求场景" prop="demandScene">
          <el-input class="form-input" type="textarea" v-model="form.demandScene" placeholder="请输入内容"
            :autosize="{ minRows: 2, maxRows: 6 }"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancel-btn" @click="handleClose">取 消</el-button>
        <el-button class="submit-btn" type="primary" @click="submitForm('form')">马上开通</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { lbsConsoleUrl, videoUrl, posterUrl, IndustryOptions } from './constants.js';
export default {
  name: 'Trial',
  data() {
    return {
      lbsConsoleUrl,
      videoUrl,
      canTrial: true, // 是否可开通试用
      dateTime: '2022-12-30日',

      // 开通试用表单弹框数据
      dialogVisible: false,
      form: {
        companyName: '',
        industry: '',
        contactPerson: '',
        contactNumber: '',
        demandScene: ''
      },
      formRules: {

      },
      rules: {
        companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        industry: [{ required: true, message: '请选择行业', trigger: 'blur' }],
        contactPerson: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
        contactNumber: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        demandScene: [{ required: true, message: '请输入内容', trigger: 'blur' }],
      },
      industryOptions: IndustryOptions,
    };
  },
  methods: {
    // 开通试用表单弹框相关方法

    showDialog() {
      if (!this.canTrial) {
        return;
      }
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs.form.resetFields(); // 重置表单字段
      this.form.industry = ''; // 将 form.industry 的值设置为空字符串
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        console.log(valid);
        if (valid) {
          // TODO: 处理表单提交逻辑 跳转控制台
        } else {
          return false;
        }
      });
    }
  },

  mounted() {
    // 创建播放器实例
    const video = new window.Omplayer({
      wrapElement: '#container',
      width: '100%',
      height: '100%',
      src: videoUrl,
      poster: posterUrl,
      fit: 'cover',
      preload: 'metadata',
      muted: true,
      autoplay: true,
      controls: true,
      loop: true,
      controlsFullScreen: false,
    });
    // 调用方法
    video.play();
  },
};
</script>

<style lang="less" scoped>
.trial {
  display: flex;
  min-width: @--content-width;
  height: calc(100vh - 72px);
  margin-top: 72px;

  // 左右两列的宽度比为1：1
  .left {
    flex: 1;
    position: relative;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; // 视频完全覆盖容器，同时保持宽高比不变，从而实现自适应容器宽高
    }

    /deep/.omp-container .omp-toolbar .omp-progressbar .omp-progress-duration .omp-progress-current::before {
      background-color: #0062FF;
    }

    /deep/ .omp-container .omp-toolbar .omp-progressbar .omp-progress-duration .omp-progress-played {
      background-color: #0062FF;
    }

    /deep/ .omp-container .omp-toolbar .omp-progressbar .omp-progress-duration .omp-progress-current {
      background-color: #0062FF;
    }

    /deep/ .omp-container .omp-toolbar .omp-progressbar .omp-progress-duration {
      background-color: #fff;
    }
  }

  .right {
    flex: 1;
    background-image: url("@{cdn-url}/image/trial/rightbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    .content-wrapper {
      margin-top: 221px;
      margin-left: 96px;
      width: 448px;

      .title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #0F1829;
      }

      .sub-title {
        margin-top: 24px;
        mix-blend-mode: normal;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: rgba(15, 24, 41, 0.7);
      }

      .desc {
        margin-top: 48px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: rgba(15, 24, 41, 0.5);

        .time {
          font-weight: 600;
          color: rgba(15, 24, 41, 0.7);
        }
      }

      .btn-wrapper {

        // 清除原生样式
        button {
          border: none;
          background: none;
          outline: none;
        }

        display: flex;
        margin-top: 32px;

        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        .experience-btn {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 113px;
          height: 40px;
          background: #0062FF;
          border-radius: 4px;
          color: rgba(255, 255, 255, 0.9);
        }

        .disabled {
          background: #B5D2FF;
        }

        .purchase-btn {
          cursor: pointer;
          margin-left: 16px;
          width: 112px;
          height: 40px;
          background: #FFFFFF;
          border: 1px solid #0062FF;
          border-radius: 4px;

          a {
            color: #0062FF;
          }
        }
      }
    }
  }
}

// 弹框样式

.form-wrapper {
  max-width: 600px;
}

/deep/ .el-dialog {
  max-width: 600px;
  min-width: 300px;
}

/deep/ .el-dialog__header {
  max-width: 600px;
  padding: 28px 32px 0;
}

/deep/ .el-dialog__body {
  max-width: 600px;
  padding: 16px 32px 0;
}

/deep/ .el-select-dropdown__item,
.selected {
  background: #EBF2FF !important;
  color: #0062FF !important;
  font-weight: 400 !important;
}

/deep/ .el-dialog__footer {
  max-width: 600px;
  padding-right: 32px;
  padding-bottom: 32px;
}

/deep/ .el-form-item__label {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #0F1829;
}

/deep/ .el-dialog__title {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0F1829;
}

/deep/ .el-select {
  width: 100%;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  content: none;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.cancel-btn {
  width: 88px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #D0D5DC;
  border-radius: 3px;

  display: flex;
  /* 设置为 flex 布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #0F1829;
}

.submit-btn {
  width: 88px;
  height: 32px;
  background: #0062FF;
  border-radius: 3px;

  display: flex;
  /* 设置为 flex 布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}
</style>
