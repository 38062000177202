<template>
  <div id="app">
    <vue-extend-layouts />
  </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout';

export default {
  name: 'App',
  components: { VueExtendLayouts },
  watch: {
    '$route.path': {
      immediate: true,
      handler(newPath) {
        console.log('newPath: ', newPath);
        this.$beacon('show_wemap_home_page', {
          path: newPath,
        });
      },
    },
  },
};
</script>

<style lang="less">
#app {
  height: 100%;
}
@font-face {
  font-style: normal;
  font-family: 'TTTGBMedium';
  src: url('./styles/TTTGB-Medium.otf') format('truetype');
}
</style>
