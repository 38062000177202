<template>
  <div class="application-scene">
    <div class="area-title">应用场景</div>
    <div class="application-scene-content">
      <div
        class="application-scene-item"
        v-for="(item, index) in list"
        :key="index"
      >
        <img :src="item.img" alt="" />
        <p>{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationScene',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.application-scene {
  .sub-template();
  .application-scene-content {
    max-width: @--content-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    .application-scene-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 24px;
      p {
        .para-text();
        font-weight: @--font-weight-bold;
        margin-top: 24px;
      }
      img {
        width: 282px;
        height: 348px;
        border-radius: 3px;
      }
      &:nth-last-child(1) {
        margin-right: 0px;
      }
    }
  }
}
</style>
