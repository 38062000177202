<template>
  <div class="solution-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Solution',
};
</script>

<style lang="less">
.solution-wrapper {
  min-width: @--content-width;
}
</style>
