<template>
  <div class="product-service">
    <div class="area-title">产品服务</div>
    <div class="product-service-container" :style="{ height: activeItem.height }">
      <div class="product-service-tab">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="product-service-tabitem"
          :class="{ 'is-active': active === index }"
          @mouseenter="handleHover(index)"
        >
          <div class="product-service-tabname">
            {{ item.name }}
          </div>
          <div class="product-service-tabcontent" v-if="active === index">
            {{ item.content }}
          </div>
        </div>
      </div>
      <img
        v-for="(item, index) in list"
        :key="index"
        :src="item.img"
        class="product-service-img"
        :class="{'fade-out': active === index, 'fade-in':active !== index}"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductService',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    activeItem() {
      return this.list[this.active];
    }
  },
  methods: {
    handleHover(index) {
      this.active = index;
    },
  },
};
</script>

<style lang="less" scoped>
.product-service {
  .fade-effect();
  .sub-template();
  background-color: @--color-light;
  .product-service-container {
    width: @--content-width;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    .product-service-tab {
      text-align: left;
      .product-service-tabitem {
        width: 500px;
        padding: 24px 40px;
        background-color: @--color-white;
        margin: 10px 0px;
        font-weight: 400;
        box-sizing: border-box;
        cursor: default;
        &.is-active {
          border-left: 2px solid #10131f;
          .product-service-tabname {
            font-weight: @--font-weight-link;
            padding: 6px 0;
          }
        }
        .product-service-tabname {
          .para-text();
        }
        .product-service-tabcontent {
          .base-text();
          color: @--color-tip;
          width: 420px;
          margin: 6px 0;
        }
      }
    }
    .product-service-img {
      box-sizing: border-box;
      position: absolute;
      border-radius: 8px;
      left: 540px;
      width: 660px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
