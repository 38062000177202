<template>
  <div class="mobile-home-container">
    <img
      src="https://industry.map.qq.com/wemap/wemap-home/image/logo/logo_white.png"
      class="logo-img"
    />
    <div class="banner-container">
      <!-- 轮播 -->
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="banner-content">
            <div class="content-area">
              <h1 class="banner-title">WeMap</h1>
              <h1 class="banner-title">腾讯地图产业版</h1>
              <p class="banner-text">
                腾讯面向智慧产业的数字地图底座，产品体系重磅升级
              </p>
              <!-- <button class="banner-btn" @click="onPlayVideo"><img src="https://industry.map.qq.com/wemap/wemap-home/image/index/vedio_play@2x.png"/>视频演示</button> -->
            </div>
            <img
              class="banner-video"
              src="https://industry.map.qq.com/wemap/wemap-home/image/index/mobile_poster.png"
            />
            <!-- <video
              id="banner-video"
              class="banner-video"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="true"
              x5-video-player-type="h5-page"
              x5-video-orientation="portraint"
              x5-video-player-fullscreen="true"
              poster="https://industry.map.qq.com/wemap/wemap-home/image/index/mobile_poster.png"
              src="https://industry.map.qq.com/wemap/wemap-home/static/wemap-mobile.mp4"
              muted="muted"
              autoplay="autoplay"
              loop="loop"
              type="video/mp4"
            >
            </video> -->
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <!-- <video
        v-show="isShow"
        id="wemap-video"
        webkit-playsinline="true"
        playsinline="true"
        x-webkit-airplay="true"
        x5-video-orientation="portraint"
        x5-video-player-fullscreen="true"
        x5-video-player-type="h5"
        class="wemap-video"
        controls="controls"
        preload="auto"
        width="100%"
      >
        <source :src="video" type="video/mp4">
      </video> -->
    </div>

    <!-- 产品体系架构模块 -->
    <div class="product-architecture-area">
      <div class="area-title text-wrapper">
        构建面向产业互联网的<br />可感知、可计算、可应用的数字地图底座
      </div>
      <div class="img-wrapper"><img class="intro-img" src="https://industry.map.qq.com/wemap/wemap-home/image/index/framework.png" /></div>
    </div>

    <!-- 产品体系 -->
    <div class="product-system-area">
      <div class="area-title">核心产品体系</div>
      <mobile-gallery :list="productList"></mobile-gallery>
    </div>

    <!-- 核心优势 -->
    <div class="key-advantage-area">
      <div class="area-title">核心优势</div>
      <div class="key-advantage-content">
        <div
          class="advantage-item"
          v-for="(advantage, index) in advantageList"
          :key="index"
        >
          <img :src="advantage.icon" class="item-icon" />
          <span class="item-name">{{ advantage.name }}</span>
        </div>
      </div>
    </div>

    <!-- 解决方案 -->
    <div class="solution-area">
      <div class="area-title">全面、专业、智能的解决方案</div>
      <mobile-gallery :list="solutionList" mode="solution"></mobile-gallery>
    </div>

    <!-- 行业合作 -->
    <div class="industry-cooperation-area">
      <div class="area-title">合作伙伴</div>
      <div class="company-list">
        <div v-for="i in 7" :key="i">
          <img
            :src="`https://industry.map.qq.com/wemap/wemap-home/image/index/companies/company_${
              (i - 1) * 3 + j
            }.png`"
            v-for="j in 3"
            :key="j"
            class="company-item"
          />
        </div>
        <!-- <div class="company-item"></div> -->
      </div>
    </div>

    <div class="cooperation-area">
      <div class="area-title">立即接入，开始使用腾讯地图产业版</div>
      <div class="contact-btn" @click="onClickCooperation">商务合作</div>
    </div>

    <div class="mobile-footer">
      <div class="footer-text">
        Copyright @ 1998-{{ new Date().getFullYear() }} Tencent All Rights
        Reserved
      </div>
      <div class="footer-text">腾讯公司版权所有</div>
    </div>

    <div class="cooperation-dialog" v-show="isDialogShow">
      <div class="title-area">
        <img
          src="https://industry.map.qq.com/wemap/wemap-home/image/index/icon_close.png"
          class="icon-close"
          @click="onClickClose"
        />
        <div class="content-title">商务合作</div>
      </div>
      <div class="cooperation-content">
        <div class="content-tip">
          为了更好的了解您的需求，请按照邮件模版信息填写，并以邮件正文形式发送给我们，以便商务同学可以尽快跟您取得联系。
        </div>

        <div class="template-info">
          <div class="info-title">
            <span class="title-name">1.邮件地址</span>
            <span class="copy-area" @click="onCopy('mail')">
              <img
                src="https://industry.map.qq.com/wemap/wemap-home/image/index/icon_copy.png"
                class="icon-copy"
              />
              <span class="copy-text">点击复制邮件地址</span>
            </span>
          </div>
          <div class="mail-address mail-copy">
            {{ email }}
          </div>
        </div>

        <div class="template-info">
          <div class="info-title">
            <span class="title-name">2.邮件模版</span>
            <span class="copy-area" @click="onCopy('template')">
              <img
                src="https://industry.map.qq.com/wemap/wemap-home/image/index/icon_copy.png"
                class="icon-copy"
              />
              <span class="copy-text">点击复制邮件模板</span>
            </span>
          </div>

          <div class="template-copy">
            <div class="info-area">
              <div class="subtitle-text">单位名称</div>
              <div class="example-text">例：xxxx有限公司</div>
            </div>
            <div class="info-area">
              <div class="subtitle-text">联系人</div>
              <div class="example-text">例：xxxx</div>
            </div>
            <div class="info-area">
              <div class="subtitle-text">联系方式</div>
              <div class="example-text">例：手机号136 xxxx xxxx</div>
            </div>
            <div class="info-area">
              <div class="subtitle-text">商务需求</div>
              <div class="info-tip">
                详尽真实的项目描述，有助于更好的了解您的需求，商务同学也会优先与您取得联系。
              </div>
              <div class="example-text">
                例：该项目为XXX规模，由XXX发起，XXX负责实施，该项目目的是对目标商业地块进行商业价值评估，为项目商业分析决策提供数据依据。目前该项目已经为XX客户，XX客户提供了数据分析等服务，等等
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="contact-btn" :href="`mailto:${email}`">
        <img
          src="https://industry.map.qq.com/wemap/wemap-home/image/index/icon_mail.png"
          class="icon-mail"
        />
        <span>联系我们</span>
      </a>
    </div>
  </div>
</template>
<script>
import MobileGallery from './components/tab-gallery/mobile-gallery';
import ScrollReveal from 'scrollreveal';

const animation = {
  advantage: null,
  company: null,
  init() {
    this.company = document.getElementsByClassName('company-list');
    this.bind();
  },
  bind() {
    this.conf(this.company, '20px', 500);
  },
  conf(ele, distance, speed) {
    [ele] = ele;
    const eleLenght = ele.childNodes.length;
    function animationFn(i) {
      return {
        reset: false,
        distance,
        duration: speed, // 动画持续时间
        delay: (i + 1) * 130, // 延迟
        opacity: 0, // 初始透明度: 0.2到1的效果
        easing: 'ease-in-out', // 动画效果:缓动'ease', 'ease-in-out'，'linear'...
      };
    }
    for (let i = 0; i < eleLenght; i++) {
      // eslint-disable-next-line new-cap
      ScrollReveal().reveal(ele.childNodes[i], animationFn(i));
    }
  },
  destroy() {
    // eslint-disable-next-line new-cap
    ScrollReveal().destroy();
  },
};

export default {
  name: 'IndexMobile',
  metaInfo: {
    meta: [
      {
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1, minimum-scale=1, user-scalable=no',
      },
    ],
  },
  components: {
    MobileGallery,
  },
  mounted() {
    // const video = document.getElementById('wemap-video');
    // video.addEventListener('play', this.onPlayVideo);
    // video.addEventListener('webkitendfullscreen', this.onCloseVideo);
    // video.addEventListener('x5videoexitfullscreen', this.onCloseVideo);
    animation.init();
    // document.addEventListener('WeixinJSBridgeReady', () => {
    //   document.getElementById('banner-video').play();
    // });
  },
  data() {
    return {
      swiperOptions: {
        // 一期仅存在一个banner，暂时关闭轮播，关闭拖拽
        loop: false,
        simulateTouch: false,
      },
      advantageList: [
        {
          name: '数据鲜活完备',
          icon: 'https://industry.map.qq.com/wemap/wemap-home/image/index/icon_lbs.png',
        },
        {
          name: '引擎能力丰富',
          icon: 'https://industry.map.qq.com/wemap/wemap-home/image/index/icon_compute.png',
        },
        {
          name: '高品质低成本',
          icon: 'https://industry.map.qq.com/wemap/wemap-home/image/index/icon_data3.png',
        },
        {
          name: '高开放易被集成',
          icon: 'https://industry.map.qq.com/wemap/wemap-home/image/index/icon_data4.png',
        },
        {
          name: '低代码易组装',
          icon: 'https://industry.map.qq.com/wemap/wemap-home/image/index/icon_data5_new.png',
        },
      ],
      isDialogShow: false,
      productList: [
        {
          name: 'Builder',
          title: '地图应用构建平台',
          desc: [
            '支持用户创建发布PC、H5、小程序类型的应用。具备空间数据快速上图、多端应用快速发布、应用数据管理以及提供便捷的二次开发能力，以满足客户的多样化定制需求。',
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/builder.jpg',
          link: '/products/builder',
        },
        {
          name: 'Base',
          title: '空间数据管理中台',
          desc: [
            '基于腾讯强大的云计算能力，针对海量异构时空数据，提供高效的分布式存储、管理和查询等能力，支持空间数据发布和空间分析，为构建行业一张图提供数据管理支撑。',
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/base.jpg',
          link: '/products/base',
        },
        {
          name: 'Services',
          title: '地图服务',
          desc: [
            '基于鲜活的互联网地图数据和成熟的引擎，提供可多网部署的高性能、高可用的地图服务，为行业一张图建设提供底图、检索、路线规划等服务能力。',
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/services.jpg',
          link: '/products/service',
        },
        // {
        //   name: 'Vis',
        //   title: '地图可视化',
        //   desc: [
        //     '基于腾讯强大的渲染引擎能力，提供高品质、高性能、二三维一体化的渲染引擎，通过统一的 B/S 开放架构支撑灵活的二次开发，实现多行业、 多场景的一张图应用。',
        //   ],
        //   img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/vis.jpg',
        //   link: '/products/vis',
        // },
        {
          name: 'Mobile',
          title: '移动端地图',
          desc: [
            '依托腾讯丰富的地图数据，在专网环境下面向移动端设备提供地图展示和地图导航能力，支撑行业在移动端场景下的地图应用。',
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/mobile.jpg',
          link: '/products/mobile',
        },
        {
          name: 'Data',
          title: '地图数据',
          desc: [
            '依托腾讯专业化的数据生产体系和丰富的数据生态，提供标/高精数据采集和数据生产服务，为行业一张图提供坚实的数据基础。',
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/data.jpg',
          link: '/products/data',
        },
      ],
      solutionList: [
        {
          name: '智慧交通',
          desc: '基于WeMap对人、车、路、地、物的时空感知、数据管理、智能分析和可视化能力，构建交通业务实时态势感知、历史变化溯源、时空模拟推演的全流程解决方案，从而解决交通行业在规划、建设、管理、运营和服务方面对空间位置服务需求的问题。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/transport.png',
          link: '/solution/transport',
        },
        {
          name: '城市治理',
          desc: '基于腾讯WeMap丰富自研地图产品矩阵和鲜活的时空大数据 ，结合政府拥有的空间数据和业务数据，面向城市治理提供强大的分析处理及地图可视化能力，实现城市运行的“一图统揽、按图决策、依图施策”。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/city.png',
          link: '/solution/city',
        },
        {
          name: '智慧应急',
          desc: '基于腾讯WeMap丰富自研地图产品矩阵和鲜活的时空大数据，提供城市应急过程中全场景智能感知、可视化智能分析、高效应急处理指挥等服务，实现应急场景快速反应、科学决策和统一指挥。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/emergency.png',
          link: '/solution/emergency',
        },
        {
          name: '智慧文旅',
          desc: '基于腾讯WeMap丰富自研地图产品矩阵和鲜活的时空大数据及微信小程序原生能力，为景区提供景区可视化管控平台、智慧导览、位置大数据、专网地图等多维度的智能产品，帮助景区提升运管能力，优化游客体验。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/tourism.png',
          link: '/solution/tourism',
        },
        {
          name: '智慧零售',
          desc: '基于腾讯鲜活的时空大数据和WeMap产品能力，通过对消费者和市场的快速洞察，实现以消费者为中心，以数据为驱动，重构人货场经营策略，提升运营效率，节约成本，实现业绩增长，助力新零售。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/retail.png',
          link: '/solution/retail',
        },
        {
          name: '智慧地产',
          desc: '基于腾讯鲜活的时空大数据和WeMap产品能力，为地产行业提供从投拓拿地到销售运营的业务全生命周期数字化解决方案，助力企业管理决策提效、服务能力提升。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile/estate.png',
          link: '/solution/estate',
        },
      ],
      clipboard: null,
      video: 'https://industry.map.qq.com/wemap/wemap-home/static/wemap.mp4',
      isShow: false,
      email: 'wemap@tencent.com',
    };
  },
  destroyed() {
    this.clipboard && this.clipboard.destroy();
    // const video = document.getElementById('wemap-video');
    // video.removeEventListener('play', this.onPlayVideo);
    // video.removeEventListener('webkitendfullscreen', this.onCloseVideo);
    // video.removeEventListener('x5videoexitfullscreen', this.onCloseVideo);
    animation.destroy();
  },
  methods: {
    // onPlayVideo() {
    //   this.isShow = true;
    //   document.getElementById('wemap-video').play();
    // },
    // onCloseVideo() {
    //   this.isShow = false;
    // },
    onClickCooperation() {
      this.isDialogShow = true;
    },
    onClickClose() {
      this.isDialogShow = false;
    },
    onCopy(targetName) {
      this.clipboard = new this.$clipboard('.copy-area', {
        target: () => {
          return document.querySelector(`.${targetName}-copy`);
        },
      });
      this.clipboard
        .on('success', (e) => {
          this.$tlbsMessage.success('复制成功');
          e.clearSelection();
          this.clipboard.destroy();
        })
        .on('error', (e) => {
          console.error('Action:', e.action);
          console.error('Trigger:', e.trigger);
          this.$tlbsMessage.error('复制失败，请重试');
          this.clipboard.destroy();
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import './mobile.less';
</style>
