<template>
  <div class="product-service-container">
    <banner title="WeMap Services" subtitle="专网环境下的一站式互联网地图服务" background-img="https://industry.map.qq.com/wemap/wemap-home/image/product/service/banner.png"/>
    <advantage-list :list="advantageList"></advantage-list>
    <div class="animation-wrapper">
      <div class="ability-container dark-mode">
        <div class="container-title">我们的能力</div>
        <ability-list
          :info="renderInfo"
          :list="renderList"
        />
      </div>

      <div class="ability-container">
        <ability-list
          :info="searchInfo"
          :list="searchList"
          theme="light"
          align="right"
        />
      </div>

      <div class="ability-container dark-mode">
        <ability-list
          :info="planInfo"
        />
      </div>

      <div class="ability-container">
        <ability-list
          :info="addressInfo"
          theme="light"
          align="right"
        />
      </div>

      <div class="ability-container dark-mode">
        <ability-list
          :info="analyzingInfo"
        />
      </div>

      <div class="ability-container">
        <ability-list
          :info="snaptoroadsInfo"
          theme="light"
          align="right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '../components/product-banner';
import AdvantageList from '../components/advantages-list';
import AbilityList from '../components/ability-list';
import { animation } from '@/utils';

export default {
  name: 'ProductService',
  components: {
    Banner,
    AdvantageList,
    AbilityList,
  },
  data() {
    return {
      advantageList: [
        { name: '鲜活的地图数据', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/advantage1.png' },
        { name: '强大的服务引擎能力', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/advantage2.png' },
        { name: '安全的私有化部署', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/advantage3.png' },
      ],
      renderInfo: {
        title: '底图渲染',
        desc: '丰富的底图渲染服务，满足各个行业下对地图展示的需求。',
      },
      renderList: [
        { name: '3D地图', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/render1.png' },
        { name: '个性化地图', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/render2.png' },
      ],
      searchInfo: {
        title: '地点搜索',
        desc: '基于互联网海量鲜活地点(POI)数据，提供多种搜索能力。',
      },
      searchList: [
        { name: '关键词检索', tip: '用户可通过关键字对地点进行检索。对于关键字检索，关键字可以是POI名称、道路等，并支持模糊检索。', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/search1.png' },
        { name: '分类检索', tip: '用户可通过选择POI类别进行快速高效的垂直目的检索，检索结果带位置信息，解析后可直接在地图端展示。', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/search2.png' },
        { name: '周边检索', tip: '用户提供中心点位置和检索半径进行检索，返回周边相关的POI信息，检索结果带位置信息，解析后在地图端展示。', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/search3.png' },
        { name: '区域检索', tip: '区域检索提供根据指定城市或矩形区域，检索该范围内的相关POI信息的能力。', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/search4.png' },
      ],
      planInfo: {
        title: '路线规划',
        desc: '提供多种方式的行驶路线规划。结合智能AI模型，为用户提供合理的路线。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/plan.png',
        detail: [
          { subtitle: '路线规划服务即在用户指定出发地、目的地的情况下，经过复杂的计算，为用户提供多种策略、不同出行方式的行驶路线。支持驾车、骑行、步行。同时提供多起点、多终点的路线预估时间和预估距离的计算以满足派单、出警等调度的业务需求。', content: '' },
        ],
      },
      addressInfo: {
        title: '地址/逆地址解析',
        desc: '基于专网地图中海量鲜活的各类数据，提供准确的地址解析和逆地址解析能力。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/address.png',
        detail: [
          { subtitle: '地址解析', content: '提供把中文地址描述转换成地理坐标（经度、纬度）的能力，为用户的相关业务数据上图提供支持。' },
          { subtitle: '逆地址解析', content: '支持将地理坐标转换为易于理解的地址文本描述，即输入坐标返回地址描述信息，同时还可返回附近的POI列表供参考。' },
        ],
      },
      analyzingInfo: {
        title: '可达圈分析服务',
        desc: '提供等时和等距两种分析服务，帮助用户分析实际可达范围和区域。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/analyzing.png',
        detail: [
          { subtitle: '', content: '在限制最大行驶里程或最长行驶时间的前提下，分析用户实际可到达的范围和区域的能力，支持等时和等距两种模式。' },
        ],
      },
      snaptoroadsInfo: {
        title: '绑路服务',
        desc: '基于原始轨迹的点串坐标（轨迹点一般存在跳动、偏差情况），支持返回去噪平滑处理、匹配到道路的坐标串，起到对轨迹的修正作用。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/service/bind.png',
      },
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.product-service-container {
  .ability-container {
    overflow: hidden;
    &.dark-mode {
      color: @--color-text;
      background-color: @--color-light;
    }
    &.light-mode {
      color: @--color-text;
      background-image: linear-gradient(90deg, #FFFFFF 0%, #A1A1A1 33%, rgba(0, 0, 0, 0) 100%);
    }
    .container-title {
      .title-text();
      font-family: @--font-tencent;
      text-align: center;
      margin-top: 80px;
    }
  }
}
</style>
