<template>
  <div>
    <banner
      title="智慧零售解决方案"
      subtitle="基于腾讯鲜活的时空大数据和WeMap产品能力，通过对消费者和市场的快速洞察，实现以消费者为中心，以数据为驱动，重构人货场经营策略，提升运营效率，节约成本，实现业绩增长，助力新零售。"
      backgroundImg="https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/banner.png"
      backgroundImgStyle="linear-gradient(88deg, #060A31 0%, #020822 100%)"
    />
    <application-scene :list="sceneList"></application-scene>
    <div class="animation-wrapper">
      <product-service :list="serviceList"></product-service>
      <div class="solution-page-products">
        <div class="area-title">产品架构</div>
        <img
          src="https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/framework.png"
          width="1200px"
        />
      </div>
      <coop-case :list="caseList"></coop-case>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner';
import ApplicationScene from '../components/application-scene';
import ProductService from '../components/product-service';
import CoopCase from '../components/coop-case';
import BackgroundIcon from '../assets/icon/icon_background.png';
import FeatureIcon from '../assets/icon/icon_feature.png';
import ValueIcon from '../assets/icon/icon_value.png';
import { animation } from '@/utils';

export default {
  name: 'SolutionRerail',
  components: {
    Banner,
    ApplicationScene,
    ProductService,
    CoopCase,
  },
  data() {
    return {
      sceneList: [
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/scene1.jpg', name: '规划选址' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/scene2.jpg', name: '商圈分析' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/scene3.jpg', name: '精准铺货' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/scene4.jpg', name: '智能外勤' },
      ],
      serviceList: [
        {
          name: '魔方DRTM平台',
          content: '以腾讯LBS大数据及地理位置服务为基础，基于C端消费者和B端客户的地理分布及客户特征，对不同细分市场如街道、商圈、社区、园区、景区、学校、网格等进行多图层管理快速准确的洞察。利用机器学习、AI等技术帮助企业实现数字化网格化渠道分销及营销指引的能力，广泛应用于泛零售行业及商业领域，能够帮助企业快速实现线下业务数字化，有效管理终端零售客户。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/service1.jpg',
          height: '522px',
        },
        {
          name: '魔方移动访销平台',
          content: '基于腾讯地理位置服务，实现拜访任务执行，客户管理、协同拜访、活动执行、考勤管理、任务管理、工作总结、业绩积分等多维度精细化，数字化管理。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/service2.jpg',
          height: '522px',
        },
        {
          name: '魔方网点优选平台',
          content: '基于腾讯LBS大数据与地理位置服务能力，以消费者数据为基础，重塑人、货、场的精细化运营，数字化赋能品牌渠道战略布局，快速识别空白市场机会，追踪竞品布局，门店点址有效评估，为品牌规划选址统一标准，提供一站式数据解决方案。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/service3.jpg',
          height: '522px',
        },
        {
          name: '魔方DaaS平台',
          content: '基于腾讯LBS大数据与地理位置服务能力，将腾讯位置服务特有的空间、人口、商业数据进行结合。形成线上线下一体的智能数字化工厂，实现对人群、空间、渠道网点、购物中心的大数据分析，通过API接口的形式助力企业内部商业智能应用。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/service4.jpg',
          height: '522px',
        },
      ],
      caseList: [
        {
          name: '某餐饮连锁',
          title: '某餐饮连锁品牌选址',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['要通过移动大数据来帮助规划部门和拓展部门进行渠道铺设和拓展。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['通过腾讯LBS大数据，能够帮助品牌快速发现市线下开店机会，帮助快速决策。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['帮助网规、拓展、内部管理部门实现基大数据的快速拓展门店、销量预测、经营管理。达到降本增效的作用。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/case1.jpg',
          height: '416px',
        },
        {
          name: '某汽车后市场连锁',
          title: '某汽车后市场连锁品牌',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['品牌未来1-2年要实现门店数量翻倍的计划。直营+加盟2种形式，传统的开店模式已经无法支持品牌快速扩张。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['腾讯LBS大数据的人口分析、用车人群分析、业态分析，帮助品牌大幅提升了开店效率。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['合作之后，品牌门店数已经从1000多家拓展至今的2500家。实现了大规模开店计划，快速占领汽车后市场线下黄金位置。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/case2.jpg',
          height: '416px',
        },
        {
          name: '某大型连锁超市',
          title: '某大型连锁超市品牌',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['品牌旗下包括超市、精品超市、便利店等业态，需要通过一套大数据能力满足不同业态的业务需求。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['一套大数据满足不同业态的选址评估，经营分析，选品指导、营销作战、精准引流。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['通过LBS大数据的深度应用，提升了开店效率、门店周边客流渗透率、以及客户粘性。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/case3.jpg',
          height: '416px',
        },
        {
          name: '某饮料品牌',
          title: '某饮料品牌B端客户标杆以及UTC营销',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['品牌有数百万个B端商家渠道需要进行去伪存真和信息标准化，基于标准化后的商家进行营销导流。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['识别品牌的销售终端地理位置数据，并将位置与腾讯POI进行匹配，结合LBS地点云服务帮助品牌实现UTC（开盖有奖）的线下营销活动。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: [
                'AI算法能力可以高效识别品牌客户网点，进行网点归一化；',
                'LBS地点云服务支持千万级以上地理位置数据的存储和检索应用，为消费者提供服务的同时给终端引流。',
              ],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/retail/case4.jpg',
          height: '416px',
        },
      ],
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.solution-page-products {
  .sub-template();
}
.area-title {
  .sub-title();
}
</style>
