<template>
  <div>
    <banner
      title="智慧文旅解决方案"
      subtitle="基于腾讯鲜活的时空大数据、WeMap产品能力及微信小程序原生能力，为景区提供智慧导览、位置大数据、专网地图等多维度的智慧产品，帮助景区优化游客体验，提升运管能力。"
      backgroundImg="https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/banner.png"
      backgroundImgStyle="linear-gradient(88deg, #060A31 0%, #020822 100%)"
    />
    <application-scene :list="sceneList"></application-scene>
    <div class="animation-wrapper">
      <product-service :list="serviceList"></product-service>
      <div class="solution-page-products">
        <div class="area-title">产品架构</div>
        <img
          src="https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/framework.png"
          width="1200px"
        />
      </div>
      <coop-case :list="caseList"></coop-case>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner';
import ApplicationScene from '../components/application-scene';
import ProductService from '../components/product-service';
import CoopCase from '../components/coop-case';
import BackgroundIcon from '../assets/icon/icon_background.png';
import FeatureIcon from '../assets/icon/icon_feature.png';
import ValueIcon from '../assets/icon/icon_value.png';
import { animation } from '@/utils';

export default {
  name: 'SolutionTourism',
  components: {
    Banner,
    ApplicationScene,
    ProductService,
    CoopCase,
  },
  data() {
    return {
      sceneList: [
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/scene2.jpg',
          name: '综合管控',
        },
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/scene3.jpg',
          name: '安全运营',
        },
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/scene4.jpg',
          name: '园区管理',
        },
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/scene1.jpg',
          name: '游客服务',
        },
      ],
      serviceList: [
        {
          name: '景区孪生智慧大脑',
          content:
            '腾讯云景区孪生智慧大脑，以景区整合管理核心，统一覆盖景区所有业务核心，帮助客户智能化、一站式监控与管理旅游景区。提供“景区综合管理”、“运行监控”、“综合办公”、“资源管理”、“景区数据中心”、“物联网设备管理”等功能，全面覆盖旅游景区、文旅小镇、乡村文旅和涉旅企业的核心管理核心业务需求。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/tourism-brain.jpg',
          height: '546px',
        },
        {
          name: '腾讯云智慧导览',
          content:
            '腾讯云智慧导览基于微信小程序产品形态，让游客用手机便可一键了解景区全貌、景区介绍、推荐游玩路线、景点讲解等内容，以及查找要找的设施场所；让游客仿佛拥有私人专属导游一般畅游景区。产品功能包括：实时定位、手绘地图、推荐路线、路线规划、语音讲解、设施查找、通知推送、点位收藏、排队时间、景区名片、VR全景、AI智能导游、AR导游、AR互动等。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/service1.png',
          height: '546px',
        },
        {
          name: '腾讯文旅客情分析平台',
          content:
            '腾讯文旅客情分析平台基于整个腾讯应用生态体系海量定位数据统计能力、多维度特征提取能力以及海量数据计算处理引擎能力，高度还原现实世界客流分布情况。在无需硬件铺设支持的情况下，可以提供景区实时人数、当日人流预测、实时热力分布等多维客流在园状态分析数据，并构建了丰富客流画像体系。可以帮助景区管理者全面深入了解客流情况以及客群画像，提升景区安全运营、精准运营及营销能力。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/service2.jpg',
          height: '546px',
        },
        {
          name: '腾讯文旅专网地图',
          content:
            '腾讯专网地图平台是一款专为政府、企业用户打造的专有化地图服务平台产品。它是 集数据、GIS、导航、位置服务为一体的综合开放性地图平台可支持在私有云、物理机、专网环境下快速集成地图能力，为用户提供专业的一站式地图服务能力。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/service4.jpg',
          height: '546px',
        },
      ],
      caseList: [
        {
          name: '某小镇可视化大屏',
          title: '某小镇可视化大屏',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['随着智慧文旅建设水平不断升级，景区管控平台的建设不但要满足数据全，还要提升视觉精美度；基于文旅场景WeMap通过算法优化能力积累结合某项目实现精美、生动、细致的景区孪生展示效果。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['还原度高，身临其境，渲染效果与原模型品质提升幅度大。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['可以为景区/旅游目的地的管理团队带来更加直观的洞察基础，同时为领导汇报以及访客接待提供了较好的展示工具及载体。'],
            },
          ],
          poster: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/tourism-img1.png',
          video: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/tourism-video1.mp4',
          height: '500px',
        },
        {
          name: '数字故宫',
          title: '数字故宫',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: [
                '为了整合故宫在线数字服务，观众可以通过统一入口第一时间掌握全部故宫资讯，海量的故宫数字资源可以一键搜索、一站抵达，无需在网站、微博、APP等多个数字平台上跳转。',
              ],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: [
                '数字故宫集文物数字化成果、新文创产品、知识普及、导游导览为一体可“一站式”体验在线购票、查询地理位置、云游故宫等游客服务。',
              ],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: [
                '极大提升了故宫文化传播力和游客服务能力，得到了客户和游客的一致好评。',
              ],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/case2.png',
          height: '500px',
        },
        {
          name: '一部手机游云南',
          title: '一部手机游云南',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: [
                '为系统整治云南旅游行业乱象、推动旅游产业升级，为云南旅游市场治理提供新载体和新思路。',
              ],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: [
                '充分发挥腾讯地图微信小程序独家服务优势，提供行业领先的原生手绘地图、语音讲解、推荐路线等智慧导览服务；',
                '基于腾讯海量数据能力、多维度特征分析能力以及海量数据计算能力，为客户提供高度还原现实客流分布及洞察数据服务。',
              ],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: [
                '有效提升云南游客体验及政府景区管理运营能力，重构云南旅游新生态。',
              ],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/tourism/case4.png',
          height: '500px',
        },
      ],
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.solution-page-products {
  .sub-template();
}
.area-title {
  .sub-title();
}
</style>
