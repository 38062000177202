<template>
  <div>
    <banner
      title="智慧应急解决方案"
      subtitle="基于WeMap的位置大数据及空间一张图能力，提供城市应急过程中全场景智能感知、可视化智能分析、高效应急处理指挥等服务，实现应急场景快速反应、科学决策和统一指挥。"
      backgroundImg="https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/banner.png"
      backgroundImgStyle="linear-gradient(88deg, #060A31 0%, #020822 100%)"
    />
    <application-scene :list="sceneList"></application-scene>
    <div class="animation-wrapper">
      <product-service :list="serviceList"></product-service>
      <div class="solution-page-products">
        <div class="area-title">产品架构</div>
        <img
          src="https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/framework.png"
          width="1200px"
        />
        <div class="disclaimer">*上述功能仅能为相关的灾害场景提供风险管理工具支撑。请根据不同的突发事件做好一事一案的部署准备，不要完全依赖技术手段。</div>
      </div>
      <coop-case :list="caseList"></coop-case>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner';
import ApplicationScene from '../components/application-scene';
import ProductService from '../components/product-service';
import CoopCase from '../components/coop-case';
import BackgroundIcon from '../assets/icon/icon_background.png';
import FeatureIcon from '../assets/icon/icon_feature.png';
import ValueIcon from '../assets/icon/icon_value.png';
import { animation } from '@/utils';

export default {
  name: 'SolutionEmergency',
  components: {
    Banner,
    ApplicationScene,
    ProductService,
    CoopCase,
  },
  data() {
    return {
      sceneList: [
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/scene1.jpg', name: '化工园区' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/scene2.jpg', name: '地质灾害' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/scene3.jpg', name: '防汛排涝' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/scene4.jpg', name: '治安管理' },
      ],
      serviceList: [
        {
          name: '地质灾害管理系统',
          content: '以三维GIS和物联网为基础，结合地质灾害防治基础工作，能够及时还原现场，准确评估灾害影响与辅助决策，应用于地质灾害实景排查、环境模拟、应急过程与应急能力评估以及灾后影响评估环节，从而服务于地质灾害应急工作的各个阶段。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/service1.jpg',
          height: '498px',
        },
        {
          name: '应急防汛一张图',
          content: '对城市区域防汛安全状态进行监测预警，掌握全区域水文状态，提高风险防控能力。结合三维视图进行渍水分析，辅助决策，科学疏散人员和调配救援资源。救援过程中多部门快速协同会商，提升整体城市应急能力。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/service2.jpg',
          height: '498px',
        },
        {
          name: '化工园区事故预防及应急管理智能化平台',
          content: '基于三维模型提供的高仿真场景和精准位置数据，接入物联网感知识别系统，充分发挥风险场景监测预警作用，实现对化工园区重点危化品、重大危险源及周边应急救援资源数据的可视化，打造时空应急管理一张图。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/service3.jpg',
          height: '498px',
        },
        {
          name: '建筑物抗震性能排查与地震风险评估系统',
          content: '基于数字孪生技术形成实景化、立体化、现代化的建筑物抗震性能排查与地震风险评估系统，推动和促进震灾防御、应急救援工作。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/service4.jpg',
          height: '498px',
        },
      ],
      caseList: [
        {
          name: '某省地质勘查局',
          title: '某省地质勘查局',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['地质灾害对城市的和谐发展构成了潜在的威胁。为了发挥GIS在地质灾害预警、动态监测、灾情评估、辅助决策中的作用。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['建设地质灾害专业数据库，形成实景化、立体化、现代化的地质灾害应急排查时空信息云平台。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['推动和促进地质灾害防御、应急救援的科学化、直观化、快捷化，同时实现防灾减灾公共服务社会化。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/case1.jpg',
          height: '444px',
        },
        {
          name: '某省地震局',
          title: '某省地震局',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['集震害预测、地震应急辅助决策，降低地震灾害损失，建设建筑物抗震性能排查与地震风险评估的系统。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['提供防震减灾信息管理、数据查询、地震科普知识宣传教育，建筑物和生命线工程的地震易损性、次生灾害源影响范围等进行分析和评估，结合断裂、滑坡、液化等不利场地为城市规划提供服务，还可为其它灾害防御、城市科学化管理和相关行业提供基础资料信息服务。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['平台有效提升本地地震灾害风险防治水平，促进防震减灾现代化建设。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/case2.jpg',
          height: '444px',
        },
        {
          name: '某市化工园区',
          title: '某市化工园区',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['生产安全事关国计民生，化工园区作为化工企业聚集地，安全问题首当其冲。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['通过接入企业实时监测和视频监控数据，依托危化品登记管理系统、危化品GIS基础数据，通过信息化手段，实现动态预警、风险分布、在线巡查等功能，为综合分析、风险防范、态势研判、事故应急提供支持。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['提供支撑事故救援的事故信息，应急资源、救援措施的智能提取，增强对突发事件的分析研判、预测预警、处置决策能力，全面提升园区的管理水平。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/case3.jpg',
          height: '444px',
        },
        {
          name: '某市应急防汛一张图',
          title: '某市应急防汛一张图',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['为贯彻以人民为中心的思想，坚持生命至上，确保人民群众生命安全，精准把握城市汛情。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['融合三维GIS、物联网、快速实景三维建模、变化检测等能力，构建实景三维防汛应急管理体系一张图。支持对现场进行真实模拟，及时还原现场，准确评估灾害影响，并结合LBS提供指挥调度能力，辅助应急指挥决策。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['最终实现事前精准预警，事中智能决策，事后科学分析，构建“安监、安防、应急”三位一体的安全管理体系，全面提升城市应急管理水平。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/emergency/case4.jpg',
          height: '444px',
        },
      ],
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.disclaimer {
  margin-top: 56px;
  font-size: 14px;
  color: #757575;
}
.solution-page-products {
  .sub-template();
  padding-bottom: 64px
}
.area-title {
  .sub-title();
}
</style>
