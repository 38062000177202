
import axios from 'axios';
const isTest = process.env.VUE_APP_SERVER_ENV === 'test';
const http = async ({ ...options }) => {
  // 测试环境
  if (isTest) {
    if (process.server && process.env.NODE_ENV !== 'development') {
      options.baseURL = 'http://10.61.84.11:10106';
    } else if (options.type === 'search') {
      options.baseURL = '//sirius.sparta.html5.qq.com'; // 检索落地测试接口
    } else if (options.type === 'console') {
      options.baseURL = '//lbsconsole.sparta.html5.qq.com'; // 反馈测试接口
    } else {
      options.baseURL = '//sparkformal.testsite.woa.com/testcms';
    }
  } else {
    // 线上环境
    if (options.type === 'search') {
      options.baseURL = 'https://apis.map.qq.com/es';// 检索落地线上接口
    } else if (options.type === 'console') {
      options.baseURL = '//lbsconsole.map.qq.com'; // 反馈测试接口
      // options.baseURL = '//lbsconsole.sparta.html5.qq.com';
    } else {
      options.baseURL = 'https://lbs.qq.com';
    }
  }

  // get 请求通过params发送参数
  options.method = options.method || 'get';
  if (options.method === 'get') {
    options.params = options.data;
    delete options.data;
  }
  // CORS跨域携带cookie
  options.withCredentials = true;
  return new Promise((resolve, reject)=>{
    axios(options)
      .then(res=>{
        if (res.status === 200) {
          if (res.data.detail && res.data.info.error === 0) {
            resolve(res.data.detail);
          } else if (options.type === 'search' && res.data.status === 0) {
            resolve(res.data);
          } else if (options.type === 'console') {
            resolve(res.data);
          } else {
            resolve({});
          }
        } else {
          reject(res.data.info.msg);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default http;


