<template>
  <div class="product-advantage-container">
    <div class="container-title">我们的优势</div>
    <div class="advantage-list">
      <div class="advantage-item" v-for="(item, index) in list" :key="index">
        <img :src="item.img" class="advantage-img">
        <div class="advantage-name">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductAdvantage',
  props: {
    list: {
      type: Array,
      default: () => [],
    }
  },
};
</script>

<style lang="less" scoped>
.product-advantage-container {
  box-sizing: border-box;
  height: 520px;
  background-image: url('https://industry.map.qq.com/wemap/wemap-home/image/product/advantage-background.png');
  background-color: @--color-white;
  .background-image-center();
  background-size: 2400px 520px;
  padding-top: 80px;
  color: @--color-text;
  .container-title {
    .title-text();
    font-family: @--font-tencent;
    text-align: center;
    margin-bottom: 56px;
  }
  .advantage-list {
    display: flex;
    justify-content: center;
    .advantage-item {
      margin: 0 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .advantage-img {
        width: 140px;
        margin-bottom: 24px;
      }
      .advantage-name {
        .para-text();
        word-break: keep-all;
        font-weight: 600;
      }
    }
  }
}
</style>
