<template>
  <div class="product-banner" :style="{ backgroundImage: `url(${backgroundImg}), radial-gradient(48% 142%, #000B33 44%, #000B33 100%)` }">
    <div class="banner-content">
      <div class="banner-title">{{title}}</div>
      <div class="banner-subtitle">{{subtitle}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductBanner',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    backgroundImg: {
      type: String,
      default: '',
    }
  },
};
</script>

<style lang="less" scoped>
.product-banner {
  box-sizing: border-box;
  padding-top: 72px;
  height: 520px;
  background-color: #0D1041;
  .background-image-center();
  background-size: 2400px 520px, auto;
  .banner-content {
    width: @--content-width;
    margin: 0 auto;
    color: @--color-white;
    .banner-title {
      .title-banner();
      font-family: @--font-tencent;
      margin-top: 138px;
      margin-bottom: 16px;
    }
    .banner-subtitle {
      .base-text();
      text-align: justify;
      width: 560px;
      opacity: 0.6;
    }
  }
}
</style>
