<template>
  <div class="ecology">
    <banner
      title="生态合作伙伴计划"
      subtitle="WeMap腾讯地图产业版合作伙伴计划是腾讯地图⾯向智慧产业⽅向的合作伙伴⻓期合作计划，⾯向全社会招募⾏业、区域、集成合作⽅向的伙伴。腾讯地图将开放地图数字基础平台能⼒，构建共创、共建、共享的地图新基建⽣态。"
      backgroundImg="https://industry.map.qq.com/wemap/wemap-home/image/ecology/banner.png"
      backgroundImgStyle="linear-gradient(88deg, #060A31 0%, #020822 100%)"
    />
    <div class="ecology-module">
      <div class="area-title">生态伙伴全景图</div>
      <ecology-partner  :list="partnerList"></ecology-partner>
    </div>
    <div class="ecology-module ecology-benefit">
      <div class="area-title">伙伴权益</div>
      <card-list :list="cardList"></card-list>
    </div>
    <div class="ecology-module">
      <div class="area-title">合作伙伴</div>
      <logo-list :list="logoList"></logo-list>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner';
import EcologyPartner from './components/ecology-partner';
import CardList from './components/card-list';
import LogoList from './components/logo-list';
import ScrollReveal from 'scrollreveal';

const animation = {
  card: null,
  company: null,
  init() {
    this.card = document.getElementsByClassName('card-list');
    this.company = document.getElementsByClassName('logo-list');
    this.bind();
  },
  bind() {
    this.conf(this.card, '20px', 500);
    this.conf(this.company, '20px', 500);
  },
  conf(ele, distance, speed) {
    [ele] = ele;
    const eleLenght = ele.childNodes.length;
    function animationFn(i) {
      return {
        reset: false,
        distance,
        duration: speed, // 动画持续时间
        delay: (i + 1) * 130, // 延迟
        opacity: 0, // 初始透明度: 0.2到1的效果
        easing: 'ease-in-out', // 动画效果: 缓动'ease', 'ease-in-out'，'linear'...
      };
    }
    for (let i = 0; i < eleLenght; i++) {
      // eslint-disable-next-line new-cap
      ScrollReveal().reveal(ele.childNodes[i], animationFn(i));
    }
  },
  destroy() {
    // eslint-disable-next-line new-cap
    ScrollReveal().destroy();
  },
};

export default {
  name: 'Ecology',
  components: {
    Banner,
    EcologyPartner,
    CardList,
    LogoList,
  },
  data() {
    return {
      partnerList: [
        {
          name: '集成合作伙伴',
          labelList: ['项目管理', '集成验证', '技术负责', '整体交付'],
          tip: '集成合作伙伴是指具备项⽬管理、集成验证、采购管理等能⼒，在WeMap各⾏业场景化解决⽅案中承担集成⻆⾊的伙伴，对项⽬整体交付和技术负责。',
          img: 'https://industry.map.qq.com/cloud/wemap/ecology/partner1.png',
        },
        {
          name: '行业合作伙伴',
          labelList: ['智慧城市', '智慧园区', '综合治理', '城市规划', '智慧能源'],
          tip: '⾏业合作伙伴是指深耕⾏业，洞悉⾏业场景的合作伙伴，拥有⾏业产品⾃主研发能⼒，提供⾏业整体解决⽅案的信息化服务企业。',
          img: 'https://industry.map.qq.com/cloud/wemap/ecology/partner2.png',
        },
        {
          name: '区域合作伙伴',
          labelList: ['华北区域', '华南区域', '华西区域', '华东区域', '华中区域'],
          tip: '区域合作伙伴是指扎根区域，结合区域发展点，围绕WeMap产品和解决⽅案，为当地的政企客户提供本地化的地理信息技术服务。',
          img: 'https://industry.map.qq.com/cloud/wemap/ecology/partner3.png',
        },
      ],
      cardList: [
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/ecology/icon1.png',
          name: '营销支持',
          desc: '销售机会、客户资源共享，由专业的销服团队全程予以跟踪。有机会进入伙伴推荐目录，获得线上、线下渠道的联合宣传与推广机会。',
        },
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/ecology/icon2.png',
          name: '培训支持',
          desc: '根据伙伴的不同需求，由资深工程师、方案架构师、以及行业专家提供定制化的培训方案。内容包括但不限于产品赋能培训、联合方案开发、以及行业趋势解读。',
        },
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/ecology/icon3.png',
          name: '能力支持',
          desc: '作为前沿科技的孵化器，腾讯人工智能实验室和前沿科技实验室在人工智能、大数据、云计算、位置服务等方面的能力积累，开放腾讯技术，保障联合产品、方案在实践场景中的落地。',
        },
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/ecology/icon4.png',
          name: '生态支持',
          desc: '开放腾讯的生态资源，既能拥有广泛C端用户的触达能力，也能与众多伙伴联动合作、优势互补、共建智慧产业新生态。',
        },
      ],
      logoList: [
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo1.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo2.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo3.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo4.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo5.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo6.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo7.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo8.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo9.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo10.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo11.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo12.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo13.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo14.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo15.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo16.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo17.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo18.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo19.jpg',
        'https://industry.map.qq.com/wemap/wemap-home/image/ecology/logo20.jpg',
      ],
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less">
.ecology {
  min-width: 1200px;

  .ecology-module {
    .sub-template();
  }

  .ecology-benefit {
    background: @--color-light;
  }

  .area-title {
    .sub-title();
  }
}
</style>
