<template>
  <div>
    <banner
      title="城市治理解决方案"
      subtitle="基于腾讯鲜活的时空大数据和WeMap产品能力，结合政府拥有的空间数据和业务数据，面向城市治理提供强大的分析处理及可视化能力，实现城市运行的“一图统揽、按图决策、依图施策”。"
      backgroundImg="https://industry.map.qq.com/wemap/wemap-home/image/solution/city/banner.png"
      backgroundImgStyle="linear-gradient(88deg, #060A31 0%, #020822 100%)"
    />
    <application-scene :list="sceneList"></application-scene>
    <div class="animation-wrapper">
      <product-service :list="serviceList"></product-service>
      <div class="solution-page-products">
        <div class="area-title">产品架构</div>
        <img
          src="https://industry.map.qq.com/wemap/wemap-home/image/solution/city/framework.png"
          width="1200px"
        />
      </div>
      <coop-case :list="caseList"></coop-case>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner';
import ApplicationScene from '../components/application-scene';
import ProductService from '../components/product-service';
import CoopCase from '../components/coop-case';
import BackgroundIcon from '../assets/icon/icon_background.png';
import FeatureIcon from '../assets/icon/icon_feature.png';
import ValueIcon from '../assets/icon/icon_value.png';
import { animation } from '@/utils';

export default {
  name: 'SolutionCity',
  components: {
    Banner,
    ApplicationScene,
    ProductService,
    CoopCase,
  },
  data() {
    return {
      sceneList: [
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/scene1.jpg',
          name: '用图上报',
        },
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/scene2.jpg',
          name: '一图统揽',
        },
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/scene3.jpg',
          name: '依图施策',
        },
        {
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/scene4.jpg',
          name: '按图决策',
        },
      ],
      serviceList: [
        {
          name: '指挥一张图系统',
          content:
            '在城市一网统管业务中，将各类业务资源、城市部件、社会资源等数据整合于一张图上，实现城市运行管理过程中政府机关、城市部件、社会力量的全面贯通、一张图上资源大集中、跨部门跨区域协同指挥。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/service1.jpg',
          height: '400px',
        },
        {
          name: '空间数据管理平台',
          content:
            '在城市CIM建设过程中，支撑各类空间业务数据接入、管理、查询，同时根据应用场景提供各类空间分析能力，并将制图结果进行服务发布，实现数据的二次利用，做到城市数据资源的一体化管理。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/service2.jpg',
          height: '400px',
        },
        {
          name: '人群热力分析平台',
          content:
            '以腾讯地图与腾讯位置大数据为基础，为用户提供重点关注区域的实时人数、人群分布、人群画像、人群来源、未来人数预测等能力，让用户对城市人群分布了如指掌。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/service3.jpg',
          height: '400px',
        },
      ],
      caseList: [
        {
          name: '某城市运行管理平台',
          title: '某城市运行管理平台',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['某省明确提出加快推进城市治理现代化和解决城市管理中的堵点、盲点，全面推动城市运行管理更加科学化、精细化、智能化，根据“一网统管、全城统管”相关工作部署，按照管理精准、实战管用的要求，建设城市运行管理中枢。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: [
                '为某城市运行管理中枢提供了丰富的位置大数据服务，如人群热力分布大数据、人群迁徙大数据、城市交通拥堵态势大数据等内容。',
              ],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['基于WeMap的地图服务能力、位置大数据等能力，为城市运行管理提供统揽全局、决策指挥的科技支撑，打造了数据融合、时空多维呈现、要素智能配置的城市治理新范式。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/case1.jpg',
          height: '400px',
        },
        {
          name: '城市级控制中心',
          title: '城市级控制中心',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: [
                '基于城市治理和指挥控制场景抽象提炼出的城市运行管理控制中枢。',
              ],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: [
                '为某城市控制中心提供了三维地图渲染底座，城市风险标记并进行路径规划，辅助城市进行有效资源调度。',
              ],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: [
                '基于WeMap的地图服务能力，接入城市实时数据，联动多元组件，打造智慧城市控制与指挥中心。',
              ],
            },
          ],
          poster: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/city-img1.png',
          video:
            'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/city-video1.mp4',
          height: '400px',
        },
        {
          name: '深圳智慧城市演示平台',
          title: '深圳智慧城市演示平台',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['基于宏观到微观从城市商圈分布、人口画像、人流分布以及人流迁徙四个方面为城市管理提供数据分析，通过直观的方式提供决策依据。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: [
                '真实的三维城市可视化呈现，客观还原现实世界；',
                '实时鲜活的时空大数据，有效的辅助决策。',
              ],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: [
                '准确地分析城市人口状态和商圈分布；',
                '实时监控城市数据，做到风险预判。',
              ],
            },
          ],
          poster: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/city-img3.png',
          video:
            'https://industry.map.qq.com/wemap/wemap-home/image/solution/city/city-video3.mp4',
          height: '400px',
        },
      ],
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.solution-page-products {
  .sub-template();
}
.area-title {
  .sub-title();
}
</style>
