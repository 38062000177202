import BeaconAction from '@tencent/beacon-web-sdk';

// 埋点初始化
const appkey = '0WEB05T8XT4T38CC';
const beaconInstance = new BeaconAction({
  appkey, // 系统或项目id, 必填
  versionCode: '2.8.6', // 项目版本,选填
  channelID: `web_${process.env.NODE_ENV}`, // 渠道,选填
  isDebug: false, // 是否开启实时联调, 默认关闭,选填
  strictMode: false, // 严苛模式开关, 打开严苛模式会主动抛出异常, 上线请务必关闭!!!
  delay: 3000, // 普通事件延迟上报时间(单位毫秒), 默认3000(3秒),选填
  sessionDuration: 60 * 1000, // session变更的时间间隔, 一个用户持续30分钟(默认值)没有任何上报则算另一次 session
});

const beaconReport = (type, params) => {
  // 公用参数
  const loginLevel = getCookie('login_level') || '';
  const loginType = getCookie('login_type') || '';

  beaconInstance.onUserAction(type, {
    login_level: loginLevel,
    login_type: loginType,
    ...params,
  });
};
const getCookie = (name) => { // 获取cookie 用于判断是否登陆
  const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
  const arr = document.cookie.match(reg);
  if (arr) {
    return decodeURIComponent(arr[2]);
  }
  return null;
};
export { beaconReport, getCookie };
