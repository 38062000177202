<template>
  <div class="ecology-partner-container">
     <div class="ecology-partner-img">
       <img
        v-for="(item, index) in list"
        :key="index"
        :src="item.img"
        class="product-service-img"
        :class="{'fade-out': active === index, 'fade-in':active !== index}"
      />
    </div>
    <div class="ecology-partner-content">
      <div
        class="ecology-partner-item"
        :class="{ 'is-active': index === active }"
        v-for="(item, index) in list"
        :key="index"
        @mouseover="handleHover(index)"
      >
        <div class="ecology-partner-name">{{ item.name }}</div>
        <div
          v-if="item.labelList && index === active"
          class="ecology-partner-label"
        >
          <div
            class="ecology-partner-labeltag"
            v-for="(labeltag, tagindex) in item.labelList"
            :key="tagindex"
          >
            {{ labeltag }}
          </div>
        </div>
        <div v-if="item.tip && index === active" class="ecology-partner-tip">
          {{ item.tip }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EcologyPartner',
  props: {
    list: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    activeItem() {
      return this.list[this.active];
    }
  },
  methods: {
    handleHover(index) {
      this.active = index;
    },
  },
};
</script>

<style lang="less" scoped>
.ecology-partner-container {
  .fade-effect();
  color: @--color-text;
  width: @--content-width;
  height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-align: left;
  .ecology-partner-img {
    margin-right: 100px;
    margin-left: 40px;
    width: 460px;
    height: 460px;
    background-size: contain;
    position: relative;
    img {
      width: 460px;
      height: 460px;
      position: absolute;
    }
  }
  .ecology-partner-content {
    width: 600px;
    .ecology-partner-item {
      cursor: default;
      padding: 24px;
      width: 550px;
      border-left: 2px solid @--color-border;
      &.is-active {
        border-left-color: @--color-text;
        background: linear-gradient(
            90deg,
            #f5f7fc 0%,
            rgba(245, 247, 252, 0) 99%
          )
          @--color-light;
        .ecology-partner-name {
          font-weight: @--font-weight-link;
        }
        .ecology-partner-tip {
          color: @--color-subtext;
        }
      }
      .ecology-partner-name {
        .para-text();
      }
      .ecology-partner-label {
        margin: 12px 0;
        .ecology-partner-labeltag {
          display: inline-block;
          margin-right: 20px;
          color: @--color-primary;
          width: 80px;
          height: 32px;
          line-height: 32px;
          border-radius: 3px;
          background-color: @--color-white;
          font-size: @--font-size-small;
          text-align: center;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
      .ecology-partner-tip {
        .base-text();
        padding: 8px 0;
      }
    }
  }
}
</style>
