<template>
  <div class="product-ai-container">
    <banner
      title="WeMap AI"
      subtitle="多维度的时空洞察"
      background-img="https://industry.map.qq.com/wemap/wemap-home/image/product/ai/banner.png"
    />
    <advantage-list :list="advantageList"></advantage-list>
    <div class="ability-container dark-mode">
      <div class="container-title">我们的能力</div>
      <ability-list
        :info="locationInfo"
        :list="locationList"
        theme="dark"
      />
    </div>

    <div class="ability-container">
      <ability-list
        :info="trailInfo"
        :list="trailList"
        theme="light"
        align="right"
      />
    </div>

    <div class="ability-container dark-mode">
      <ability-list
        :info="trafficInfo"
        :list="trafficList"
        theme="dark"
      />
    </div>

    <div class="ability-container">
      <ability-list
        :info="plotInfo"
        theme="light"
        align="right"
      />
    </div>
  </div>
</template>

<script>
import Banner from '../components/product-banner';
import AdvantageList from '../components/advantages-list';
import AbilityList from '../components/ability-list';

export default {
  name: 'ProductAI',
  components: {
    Banner,
    AdvantageList,
    AbilityList,
  },
  data() {
    return {
      advantageList: [
        { name: '海量的实时数据', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/advantage1.png' },
        { name: '精准的时空模型', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/advantage2.png' },
        { name: '丰富的应用场景', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/advantage3.png' },
      ],
      locationInfo: {
        title: '位置大数据',
        desc: '提供从人数到人流、从静态到动态、从历史/实时到未来预测的全方位的人口分析能力。',
      },
      locationList: [
        { name: '实时人口', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/location1.png' },
        { name: '常驻流动', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/location2.png' },
        { name: '区域通勤', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/location3.png' },
      ],
      trailInfo: {
        title: '轨迹大数据',
        desc: '基于车流轨迹大数据，提供城市内宏中微观一体化的交通解决方案。',
      },
      trailList: [
        { name: '宏观仿真', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/trail1.png' },
        { name: '中观仿真', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/trail2.png' },
        { name: '微观仿真', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/trail3.png' },
      ],
      trafficInfo: {
        title: '路况大数据',
        desc: '基于轨迹大数据提供道路交通实时路况，历史拥堵溯源和拥堵预测服务。',
      },
      trafficList: [
        { name: '实时路况', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/traffic1.png' },
        { name: '拥堵研判', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/traffic2.png' },
        { name: '拥堵预测', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/traffic3.png' },
      ],
      plotInfo: {
        title: '地块大数据',
        desc: '通过城市底板，全面感知城市建设全貌，洞察城市人/地/物的月度变化，协助城市评价和治理规划。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/ai/plot.png'
      },
    };
  },
};
</script>

<style lang="less" scoped>
.product-ai-container {
  .ability-container {
    overflow: hidden;
    &.dark-mode {
      color: @--color-white;
      background-image: linear-gradient(90deg, #000D2E 0%, #01143F 32%, #000F34 59%, #000C28 100%);
    }
    &.light-mode {
      color: @--color-text;
      background-image: linear-gradient(90deg, #FFFFFF 0%, #A1A1A1 33%, rgba(0, 0, 0, 0) 100%);
    }
    .container-title {
      .title-text();
      font-family: @--font-tencent;
      text-align: center;
      margin-top: 80px;
    }
  }
}
</style>
