/* eslint-disable func-names */
import ScrollReveal from 'scrollreveal';

// eslint-disable-next-line import/prefer-default-export
export function throttle(fn, wait) {
  // 上一次执行 fn 的时间
  let previous = 0;
  // 将 throttle 处理结果当作函数返回
  // eslint-disable-next-line func-names
  return function (...args) {
    // 获取当前时间，转换成时间戳，单位毫秒
    const now = +new Date();
    // 将当前时间和上一次执行函数的时间进行对比
    // 大于等待时间就把 previous 设置为当前时间并执行函数 fn
    if (now - previous > wait) {
      previous = now;
      fn.apply(this, args);
    }
  };
}

export function debounce(fun, delay) {
  clearTimeout(this.timer);
  this.timer = setTimeout(() => {
    fun();
  }, delay);
}

export function isIEBrowser() {
  const {
    userAgent
  } = navigator; // 取得浏览器的userAgent字符串
  const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1; // 判断是否IE<11浏览器
  const isEdge = userAgent.indexOf('Edge') > -1 && !isIE; // 判断是否IE的Edge浏览器
  const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
  if (isIE || isEdge || isIE11) {
    return true; // 不是ie浏览器
  }
  return false;
}

/**
 * 判断是否是安卓浏览器
 */
export function isAndroid() {
  const {
    userAgent
  } = navigator; // 取得浏览器的userAgent字符串
  const isandroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
  if (isandroid) {
    return true;
  }
  return false;
}

export function IsPC() {
  const userAgentInfo = navigator.userAgent;
  const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}
export function sendPv(options, link) {
  const PR = 'https://pr.map.qq.com/pingd';
  $.ajax({
    url: PR,
    type: 'GET',
    data: options,
    success: () => {
      if (link) {
        window.location.href = link;
      }
    },
  });
}
export function isUrl(url) {
  // eslint-disable-next-line no-useless-escape
  const reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|www\.)(([A-Za-z0-9-_~]+)\.)+([A-Za-z0-9-_~\/\.]|(:[0-9]{1,4})*)+?(\/[a-zA-Z0-9\.\-~!@#$%^&*+?:_/=<>[\u4e00-\u9fa5]*]*)$/;
  return reg.test(url);
}

export function scrollIntoViewHorizon(contaienr, selected) {
  if (!contaienr || !selected) {
    return;
  }

  const distance = selected.offsetLeft + (selected.offsetWidth / 2) - (contaienr.scrollLeft + (window.innerWidth / 2));

  contaienr.scrollLeft += distance;
}


export const animation = {
  advantage: null,
  init() {
    this.advantage = document.getElementsByClassName('animation-wrapper');
    console.log('this.advantage', this.advantages);
    this.bind();
  },
  bind() {
    this.conf(this.advantage, '20px', 500);
  },
  conf(ele, distance, speed) {
    [ele] = ele;
    const eleLenght = ele.childNodes.length;
    function animationFn(i) {
      return {
        reset: false,
        distance,
        duration: speed, // 动画持续时间
        delay: (i + 1) * 130, // 延迟
        opacity: 0, // 初始透明度: 0.2到1的效果
        easing: 'ease-in-out', // 动画效果:缓动'ease', 'ease-in-out'，'linear'...
      };
    }
    for (let i = 0; i < eleLenght; i++) {
      // eslint-disable-next-line new-cap
      ScrollReveal().reveal(ele.childNodes[i], animationFn(i));
    }
  },
  destroy() {
    // eslint-disable-next-line new-cap
    ScrollReveal().destroy();
  },
};
