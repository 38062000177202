<template>
  <div>
    <banner
      title="智慧地产解决方案"
      subtitle="基于腾讯鲜活的时空大数据和WeMap产品能力，为地产行业提供从投拓拿地到销售运营的业务全生命周期数字化解决方案，助力企业管理决策提效、服务能力提升。"
      backgroundImg="https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/banner.png"
      backgroundImgStyle="linear-gradient(88deg, #060A31 0%, #020822 100%)"
    />
    <application-scene :list="sceneList"></application-scene>
    <div class="animation-wrapper">
      <product-service :list="serviceList"></product-service>
      <div class="solution-page-products">
        <div class="area-title">产品架构</div>
        <img
          src="https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/framework.png"
          width="1200px"
        />
        <div class="disclaimer">*产品仅提供大数据分析，最终实际决策以客户自身考量为准。</div>
      </div>
      <coop-case :list="caseList"></coop-case>
      </div>
  </div>
</template>

<script>
import Banner from '@/components/banner';
import ApplicationScene from '../components/application-scene';
import ProductService from '../components/product-service';
import CoopCase from '../components/coop-case';
import BackgroundIcon from '../assets/icon/icon_background.png';
import FeatureIcon from '../assets/icon/icon_feature.png';
import ValueIcon from '../assets/icon/icon_value.png';
import { animation } from '@/utils';

export default {
  name: 'SolutionEstate',
  components: {
    Banner,
    ApplicationScene,
    ProductService,
    CoopCase,
  },
  data() {
    return {
      sceneList: [
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/scene1.jpg', name: '土地研判' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/scene2.jpg', name: '客群洞察' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/scene3.jpg', name: '经营管理' },
        { img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/scene4.jpg', name: '精准营销' },
      ],
      serviceList: [
        {
          name: '腾讯拿地通',
          content: '通过大数据辅助挖掘土地价值潜力，为企业投资布局提供决策依据。从宏观到微观快速了解地块商业价值，辅助企业进行拿地决策及后续项目市场调研定位，提升土地拓展效率、降低投资风险。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/service1.jpg',
          height: '496px',
        },
        {
          name: '腾讯客留通',
          content: '购物中心数字化经营助手，实现看客流、看画像、看商圈、看客源、看竞品，涵盖项目经营管理所需关注的相关数据视角，服务多部门业务应用，助力项目实现精细化、智慧化运营。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/service2.jpg',
          height: '496px',
        },
        {
          name: '腾讯室内通',
          content: '购物中心数字化基建“新标配”，依托微信小程序轻体验、高转化的特点，为商场打造线上版“商场门户”，解决找店、停车、互动等核心需求，贯穿购物购前、购中、购后服务体验品质升级。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/service3.png',
          height: '496px',
        },
        {
          name: '腾讯案场通',
          content: '基于城市视角的楼盘地图，辅助城市团队动态掌握在售项目的客流情况，辅助销售进行营销策略制定及调整，助力房企实现案场数字化营销管理。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/service4.jpg',
          height: '496px',
        },
      ],
      caseList: [
        {
          name: '某头部房企',
          title: '某头部房企-拿地分析',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['房地产行业在国家宏观政策调控下，持续向精细化运营方向进行升级演进，在前期的投资拓展阶段，更加需要多维数据辅助企业进行土地研判决策。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['拿地通产品可以从宏观城市到微观地块板块进行土地价值分析，提供经济、人口、产业等多维度数据助力企业拿地决策。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['通过拿地通产品能力，有效提升企业在土地研判阶段的决策效率，降低投拓风险，助力企业实现精细化运营管理。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/case1.jpg',
          height: '496px',
        },
        {
          name: '某头部地产集团',
          title: '某头部地产集团-经营分析',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['商业地产在经营阶段需要大量数据辅助项目进行运营决策，对项目的客流情况、商圈情况进行评估判断，优化调整项目运营策略，促进线下客流到访提升。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['客留通产品零实施成本，提供看客流、看画像、看商圈、看客源、看竞品等多种维度数据，助力商家精细化运营管理。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['与某地产集团旗下多个商业项目进行合作，提供客留通平台产品，助力商家管理者提升内部决策效率，有效的改善活动运营策略，为顾客提供更优质产品服务。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/case2.jpg',
          height: '496px',
        },
        {
          name: '某商业综合体',
          title: '某商业综合体：智慧小程序',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['某大型商业综合体涵盖商场、公寓、酒店、写字楼等多个业态建筑，线下环境复杂，用户停车、找店痛点强烈。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['基于微信小程序原生LBS服务能力，打造数字化服务小程序，帮助用户实现高效停车、找店、找活动，助力商家实现智慧化服务。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['为某大型商业综合体打造智慧服务小程序，帮助国贸区域内商城、饭店、酒店、公寓、写字楼等多个场景用户解决找活动、寻店、停车等需求，极大地提升了国贸的数字化服务能力，改善客户线下体验。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/case3.png',
          height: '496px',
        },
        {
          name: '某地产企业服务商',
          title: '某地产企业服务商-数字营销',
          desc: [
            {
              title: '背景',
              icon: BackgroundIcon,
              content: ['地产行业私域流量运营需求日益剧增，在营销投放场景企业需要通过数字化的手段实现精准营销，提升推广投放效率，构建私域流量池。'],
            },
            {
              title: '特点',
              icon: FeatureIcon,
              content: ['通过腾讯时空大数据能力，与某地产行业头部伙伴共建智慧引流联合产品解决方案，与腾讯广告天然打通，助力企业实现数字营销。'],
            },
            {
              title: '价值',
              icon: ValueIcon,
              content: ['通过联合产品打造，实现基于案场的客流挖掘、竞品拦截等能力，助力30+城市、600+房企项目实现数字化营销，提升投放ROI。'],
            },
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/solution/estate/case4.jpg',
          height: '496px',
        },
      ],
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.disclaimer {
  margin-top: 56px;
  font-size: 14px;
  color: #757575;
}
.solution-page-products {
  .sub-template();
  padding-bottom: 64px
}
.area-title {
  .sub-title();
}
</style>
