<template>
  <div class="product-data-container">
    <banner title="WeMap Data" subtitle="专业的数据采集与高效的数据生产" background-img="https://industry.map.qq.com/wemap/wemap-home/image/product/data/banner.png" />
    <advantage-list :list="advantageList"></advantage-list>
    <div class="animation-wrapper">
      <div class="ability-container dark-mode">
        <div class="container-title">我们的能力</div>
        <ability-list
          :info="collectionInfo"
        />
      </div>

      <div class="ability-container">
        <ability-list
          :info="rebuildInfo"
          :list="rebuildList"
          theme="light"
          align="right"
        />
      </div>

      <div class="ability-container dark-mode">
        <ability-list
          :info="dataInfo"
          :list="dataList"
        />
      </div>

      <div class="ability-container">
        <ability-list
          :info="updateInfo"
          theme="light"
          align="right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '../components/product-banner';
import AdvantageList from '../components/advantages-list';
import AbilityList from '../components/ability-list';
import { animation } from '@/utils';

export default {
  name: 'ProductData',
  components: {
    Banner,
    AdvantageList,
    AbilityList,
  },
  data() {
    return {
      advantageList: [
        { name: '成熟的数据生产体系', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/data/advantage1.png' },
        { name: '高效的自动化重建能力', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/data/advantage2.png' },
        { name: '海量数据更新能力', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/data/advantage3.png' },
      ],
      collectionInfo: {
        title: '标/高清数据的专业化采集能力',
        detail: [
          { subtitle: '标清数据采集', content: '运用大数据计算和 AI 分析能力，依托腾讯数据生态，建立自动化、高质量的数据采集生产线，可实现包括 POI、道路、建筑、水系、地址、公交在内的标清数据采集和快速更新。' },
          { subtitle: '高清数据采集', content: '将自动驾驶车辆视觉感知能力与高清地图生产相结合，腾讯自建大规模高精度采集车设备的投入，对外提供包括高清地图数据、车端应用和高清地图云服务等一体化的解决方案。' },
        ],
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/data/ability-img1.png',
      },
      rebuildInfo: {
        title: '高效的自动化重建能力',
      },
      rebuildList: [
        { name: '三维城市模型重建', tip: '以腾讯标精数据为基础，利用在地理信息图像识别方向的核心能力对建筑物的多维特征进行识别，同时对建筑物建立解构规则，实现城市级大规模建筑物的自动化三维建模', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/data/ability-img2-1.png' },
        { name: '高精道路三维重建', tip: '基于腾讯高精数据，利用长期积累的三维模型重建技术，将高精道路精细化要素进行现实还原，以达到对真实道路元素的自动化低成本复刻', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/data/ability-img2-2.jpg' },
      ],
      dataInfo: {
        title: '实时动态数据的计算分析能力',
      },
      dataList: [
        { name: '路况数据', tip: '基于多源车辆轨迹的计算分析，提供按行政区划实时路况和实时&历史路况两种方式查询路况的服务，并支持分钟级数据更新', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/data/ability-img3-1.png' },
        { name: '道路开通封闭', tip: '基于多源情报，针对不可通行道路制作道路封闭信息，体现路线规划的合理性和通畅性，并提供高实效性的道路开通封闭查询能力', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/data/ability-img3-2.png' },
      ],
      updateInfo: {
        title: '精准和鲜活的海量数据更新能力',
        desc: '基于大数据与 AI 的全流程、自动化数据生产与发布机制，实现鲜活、动态的数据更新和发布能力。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/data/ability-img4.png',
      },
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.product-data-container {
  .ability-container {
    overflow: hidden;
    &.dark-mode {
      color: @--color-text;
      background: #F5F7FC;
    }
    &.light-mode {
      color: @--color-text;
      background-image: linear-gradient(90deg, #FFFFFF 0%, #A1A1A1 33%, rgba(0, 0, 0, 0) 100%);
    }
    .container-title {
      .title-text();
      font-family: @--font-tencent;
      text-align: center;
      margin-top: 80px;
    }
  }
}
</style>
