import Home from '../views/index';
import MobileHome from '../views/index/mobile.vue';

// 核心产品
import ProductTemplate from '../views/products';
import ProductAI from '../views/products/ai';
import ProductBase from '../views/products/base';
import ProductService from '../views/products/service';
import ProductMobile from '../views/products/mobile';
import ProductData from '../views/products/data';
import ProductVis from '../views/products/vis';
import ProductBuilder from '../views/products/builder';

// 行业解决方案
import SolutionTemplate from '../views/solution';
import SolutionCity from '../views/solution/city';
import SolutionTransport from '../views/solution/transport';
import SolutionEmergency from '../views/solution/emergency';
import SolutionTourism from '../views/solution/tourism';
import SolutionRetail from '../views/solution/retail';
import SolutionEstate from '../views/solution/estate';

// 生态合作
import Ecology from '../views/ecology';

// 产品试用
import Trial from '../views/trial';

// 服务与支持
import Support from '../views/support';
import SupportWhitePaper from '../views/support/white-paper';
import SupportClause from '../views/support/clause';
import SupportPrivacy from '../views/support/privacy';
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      // layout: 'home-footer',
    },
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: MobileHome,
    meta: {
      layout: 'empty',
    }
  },
  {
    path: '/products',
    component: ProductTemplate,
    children: [
      {
        path: 'ai',
        name: 'ai',
        component: ProductAI,
      },
      {
        path: 'base',
        name: 'base',
        component: ProductBase,
      },
      {
        path: 'service',
        name: 'service',
        component: ProductService,
      },
      {
        path: 'mobile',
        name: 'mobile',
        component: ProductMobile,
      },
      {
        path: 'data',
        name: 'data',
        component: ProductData,
      },
      {
        path: 'vis',
        name: 'vis',
        component: ProductVis,
      },
      {
        path: 'builder',
        name: 'builder',
        component: ProductBuilder,
      },
    ],
  },
  {
    path: '/solution',
    component: SolutionTemplate,
    children: [
      {
        path: 'city',
        name: 'city',
        component: SolutionCity,
      },
      {
        path: 'transport',
        name: 'transport',
        component: SolutionTransport,
      },
      {
        path: 'emergency',
        name: 'emergency',
        component: SolutionEmergency,
      },
      {
        path: 'tourism',
        name: 'tourism',
        component: SolutionTourism,
      },
      {
        path: 'retail',
        name: 'retail',
        component: SolutionRetail,
      },
      {
        path: 'estate',
        name: 'estate',
        component: SolutionEstate,
      },
    ],
  },
  {
    path: '/ecology',
    name: 'ecology',
    component: Ecology,
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
    children: [
      {
        path: 'whitePaper',
        name: 'supportWhitePaper',
        component: SupportWhitePaper,
        meta: {
          layout: 'simple-footer',
        },
      },
      {
        path: 'clause',
        name: 'supportClause',
        component: SupportClause,
        meta: {
          layout: 'simple-footer',
        },
      },
      {
        path: 'privacy',
        name: 'supportPrivacy',
        component: SupportPrivacy,
        meta: {
          layout: 'simple-footer',
        },
      },
    ]
  },
  {
    path: '/trial',
    name: 'trial',
    component: Trial,
    meta: {
      layout: 'only-header',
    },
  },
];

export default routes;
