<template>
  <el-dialog
    :custom-class="
      'video-dialog ' + ($attrs['custom-class'] ? $attrs['custom-class'] : '')
    "
    :visible.sync="visible"
    :width="`${width}px`"
    :show-close="showClose"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    :lock-scroll="false"
  >
    <div>
      <video
        :src="video"
        id="videoPlayer"
        class="gray"
        webkit-playsinline="true"
        preload="metadata"
        playsinline="true"
        x-webkit-airplay="true"
        x5-video-player-type="h5"
        x5-video-orientation="portraint"
        x5-video-player-fullscreen="true"
        muted
        controls
        controlsList="nodownload"
        autoplay
        type="video/mp4"
        style="width: 100%; height: 100%; object-fit: cover"
      />
      <!-- <span class="video-dialog-close" @click="close"></span> -->
      <div class="video-dialog-close">
        <img
          class="close-icon"
          src="https://industry.map.qq.com/wemap/wemap-home/image/index/close.svg"
          @click="close"
        />
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: [Boolean],
    video: [String],
    width: {
      type: Number,
      default: 1200,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      // const dom = document.getElementById('videoPlayer');
      // dom.currentTime = 0;
      // dom.pause();
      document.getElementById('videoPlayer').pause();
      this.$emit('update:visible', false);
    },
  },
};
</script>
<style lang="less">
.video-dialog {
  &.el-dialog {
    background: transparent;
  }
  background: transparent;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    background: transparent;
    height: 675px;
  }
  video:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
  &-close {
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 2;
    text-align: right;
    width: 100%;
    .close-icon {
      cursor: pointer;
      font-size: @--font-size-medium;
      width: 24px;
      padding: 12px;
    }
  }
}
</style>
