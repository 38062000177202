const clauseList = [
  {
    title: '一、【协议的范围】',
    content: [
      {
        subtitle: '1.1 【协议适用主体范围】',
        text: [
          '经有效注册申请后，自然人、法人或其他组织（本协议中统称为“您”）可以按照本协议约定使用由深圳市腾讯计算机系统有限公司提供的WeMap服务。',
          '本协议是您与腾讯之间关于您注册、登陆、使用本服务，以及使用腾讯相关服务所订立的协议。'
        ]
      },
      {
        subtitle: '1.2 【本许可协议指向内容】',
        text: [
          '本协议项下的许可内容是指腾讯向您提供的包括但不限于腾讯WeMap腾讯地图产业版服务（以下简称“本服务”或“WeMap服务”）。',
        ]
      },
      {
        subtitle: '1.3【协议关系及冲突条款】',
        text: [
          '本协议被视为<a href="https://lbs.qq.com/userAgreements/agreements/terms" target="_blank">《腾讯位置服务开放API服务协议》</a>、<a href="https://lbs.qq.com/userAgreements/agreements/privacy" target="_blank">《腾讯位置服务隐私》</a>、<a href="http://www.qq.com/contract.shtml" target="_blank">《腾讯服务协议》</a>、<a href="https://ti.qq.com/agreement/index.html" target="_blank">《QQ软件许可及服务协议》</a>、<a href="https://ti.qq.com/agreement/index.html" target="_blank">《QQ软件许可及服务协议》</a>、<a href="https://weixin.qq.com/agreement?lang=zh_CN" target="_blank">《腾讯微信软件许可及服务协议》</a>的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与上述协议内容存在冲突的，以本协议为准。',
          '本协议内容同时包括腾讯可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。'
        ]
      }
    ]
  },
  {
    title: '二、【关于本服务】',
    content: [
      {
        subtitle: '2.1 本服务内容是指腾讯向您提供由腾讯自主研发运营的空间数据管理中台、地图服务、地图可视化以及地图应用构建平台等产品所涵盖的基础平台以及API，并通过前述产品基础平台和API应用接口向您提供包括但不限于多源多格式数据的接入、管理、分析和发布功能、地图组件快速组装的平台功能以及腾讯地点检索、路线规划等服务功能。',
        text: [
          '您在接受本协议条款约束的情况下，您通过使用基础平台或调用相应的产品应用接口可以搭建与腾讯WeMap服务相关的网站或应用（以下简称“应用”），从而在您的应用上显示腾讯地图图片、进行地点查询等操作。'
        ]
      },
      {
        subtitle: '2.2 您理解并同意，腾讯保留发布WeMap服务及相关产品后续版本以及要求您获得并使用WeMap服务及产品最新版本的权利。<span>如因您未使用最新版本的产品而导致的损失，由您自行承担。</span>',
        text: []
      },
      {
        subtitle: '2.3 腾讯有权随时对本服务规则进行调整<span>（包括但不限于终止部分服务或全部服务的提供）</span>，并通过网站公告、邮件通知、短信通知等方式予以公布。<span>您理解并同意，任何继续使用我们服务的行为，均视为您同意调整后的服务规则。</span>',
        text: []
      }
    ]
  },
  {
    title: '三、【许可及限制】',
    content: [
      {
        subtitle: '3.1 根据本协议，在您选择WeMap服务的任何一款产品时，代表腾讯给予您一项经过授权认证的、可撤销的、不可转让及非排他性的许可，以使您能够以本协议许可的方式在您开发或运营的网站或应用程序中使用您购买的具体产品或服务。',
        text: []
      },
      {
        subtitle: '<span>3.2 腾讯有权对您购买范围内的产品或者服务的调用应用接口的方式、请求次数以及数量随时进行调整或限制，具体产品价格以及对应的限制情况以您和腾讯签订的相关协议为准；腾讯可以基于自身经营状况、业务考虑随时单方面决定调整提供服务的内容而无需征求您的另行同意。腾讯对提供服务内容的调整不应视为腾讯违约。如您需要取消使用限制，您可以同腾讯另行签署书面协议或联系腾讯以获得书面同意。</span>',
        text: []
      },
      {
        subtitle: '3.3 本协议未明示授权的其他一切权利仍有腾讯保留，并且不能将本条款理解为以默示或其他方式授予您任何其他权利，您在行使这些权利时需要同腾讯另行签署书面协议或事先获得腾讯的书面许可。',
        text: []
      }
    ]
  },
  {
    title: '四、【获取本服务】',
    content: [
      {
        subtitle: '4.1 您可以通过腾讯WeMap官网、腾讯授权的合作伙伴处以及通过搜索查询方式获取本服务的访问地址。',
        text: []
      },
      {
        subtitle: '<span>4.2 如果您从未经腾讯授权的第三方获取本服务地址或与本服务名称相同但地址不同的网站，腾讯无法保证该服务能够正常使用，并对因此给您造成的损失不予负责。</span>',
        text: []
      }
    ]
  },
  {
    title: '五、【账号使用规范】',
    content: [
      {
        subtitle: '<span>5.1 在使用本服务之前，您需要注册一个账号，注册账号可通过QQ号、微信号和手机号码进行绑定。腾讯有权根据用户需求或产品需要对账号注册和绑定关系进行变更，关于您使用账号的具体规则，请遵守《QQ号码规则》、《微信个人帐号使用规范》以及腾讯为此发布的专项规则，因您的使用行为导致与其他用户或第三方发生争议的，由您独立对外承担责任，因此给腾讯、其他用户或第三方造成损害的，您应当依法予以赔偿。</span>',
        text: []
      },
      {
        subtitle: '<span>5.2 在您使用本服务的过程中，腾讯为了更好的向您提供服务，可能会要求您提供诸如邮箱、电话、地址等资料，以及要求您提供网站名称和地址、应用程序名称、公司名称和地址、公司资质证照等必要资料，您同意并保证在使用本服务过程中提供给腾讯的任何资料均是真实、准确、完整和最新的。如上述资料有任何变动，您需及时更新并通知腾讯。</span>',
        text: []
      },
      {
        subtitle: '5.3 您有责任妥善保管注册账号信息、账号户密码、密钥的安全，您需要对注册账号以及密码下的所有行为承担法律责任。您同意在任何情况下不向他人透露账号及密码信息。如果您未保管好上述资料而遭受损失或对腾讯、其他用户或任何第三方造成损害的，您应自行承担全部责任。在您怀疑他人在使用您的账号时，您应立即通知腾讯公司。',
        text: []
      }
    ]
  },
  {
    title: '六、【服务的更新、中断或终止】',
    content: [
      {
        subtitle: '6.1 为了增进用户体验、完善服务内容，腾讯将不断努力开发新的服务及产品特性，并为您不时提供服务及产品特性更新（这些更新可能会采取功能替换、修改、功能强化、版本升级、功能下架等形式）。',
        text: []
      },
      {
        subtitle: '6.2 为了改善用户体验，并保证服务的安全性和功能的一致性，腾讯有权不经向您特别通知而对服务及产品特性进行更新，或者对本服务的部分功能效果进行改变或限制或终止下架，或对部分服务决定收取费用或免费提供或不再提供。',
        text: []
      },
      {
        subtitle: '6.3 <span>如因本服务相关服务器或系统维护或升级而需中断或暂停提供本服务或本服务的一部分的，腾讯将尽可能事先进行通告。如因此类情况而造成本服务在合理时间内的中断或暂停，腾讯无需为此承担任何责任。</span>',
        text: []
      },
      {
        subtitle: '6.4 您应确保您所开发或运营的网站或应用程序以及对本服务的使用行为符合本条款的约定、符合相关法律法规的要求。如发生下列任何一种情形或本协议其他条款规定的其他终止条件发生或实现的，腾讯有权随时中断或终止向您提供本服务：',
        text: [
          '（1）您提供或上传的任何资料或信息不真实、不准确、不完整、虚假、侵权或违法；',
          '（2）您的网站或应用程序或对本服务及相关内容的使用行为违反相关法律法规或本条款的任何规定；',
          '（3）腾讯根据自己的独立判断认为您的网站或应用程序或对本服务及相关内容的使用行为不符腾讯要求或用户需求。'
        ]
      },
      {
        subtitle: '6.5 <span>除非您与腾讯达成的书面协议中另有约定，腾讯有权在不事先通知您的情况下随时变更、中断、暂停或终止提供本服务或其中一部分，也有权随时修改、暂停或终止您对本服务的使用，包括但不限于腾讯可以随时根据自己的决定来限制您使用本服务的次数、数据量等。如果腾讯永久终止提供本服务的，腾讯将会提前在网站进行公告但无需单独通知您。</span>',
        text: []
      },
      {
        subtitle: '6.6 <span>除非法律法规有明确规定或您与腾讯达成的书面协议中另有约定，对于因腾讯对本服务进行变更、中断或终止而对您或第三方造成的任何损失或后果，均由您自行承担，腾讯无需因此对您或任何第三方承担责任。</span>',
        text: []
      }
    ]
  },
  {
    title: '七、【用户信息】',
    content: [
      {
        subtitle: '7.1 保护用户信息是腾讯的一项基本原则，腾讯将会采取合理的措施保护用户的信息。腾讯对相关信息采用专业加密存储与传输方式，保障用户信息的安全。',
        text: []
      },
      {
        subtitle: '7.2 腾讯将运用各种安全技术和程序建立完善的管理制度来保护您的信息，以免遭受未经授权的访问、使用或披露。',
        text: []
      },
      {
        subtitle: '7.3 您在使用本服务的过程中，可能需要您提供一些必要的信息，如为您提供注册登录服务或进行用户身份识别时，需要您提供您使用的手机号、授权登陆您本人的QQ号及/或微信号、微信公众号、企业信息（如您为企业用户）等，用于验证用户身份信息、各个产品服务鉴权、找回密码等。若您不提供或提供的信息不完整，则无法使用本服务或在使用过程中受到限制。',
        text: []
      },
      {
        subtitle: '7.4 未经您的同意，腾讯不会向腾讯以外的任何公司、组织和个人披露您的信息，但法律法规另有规定的除外。',
        text: []
      },
      {
        subtitle: '7.5 您在使用本平台产生的产品并向其他用户提供服务时，应遵守相关法律法规收集或使用用户个人信息，因此产生的相关争议、投诉、行政处罚等均与腾讯无关，由您自行承担。',
        text: []
      },
      {
        subtitle: '7.6 具体的个人信息保护情况，请参考我们的<a href="/support/privacy" target="_blank">《WeMap隐私保护指引》</a>。',
        text: []
      }
    ]
  },
  {
    title: '八、【主权利义务条款】',
    content: [
      {
        subtitle: '8.1【用户注意事项】',
        text: [
          '8.1.1 您理解并同意：为了向您提供有效的服务，您在此许可腾讯利用您电脑及移动通讯终端的处理器和带宽等资源。本平台以及通过本平台所产生的产品在使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。',
          '8.1.2 您在使用本平台某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），您在使用该项服务前请阅读并同意相关的单独协议。',
          '8.1.3 用户在使用本平台及服务时，须自行承担如下包括但不限于腾讯不可掌控的风险内容：',
          '8.1.3.1 由于不可抗拒因素引起的个人账号信息丢失、泄漏等风险；',
          '8.1.3.2 由于网络信号不稳定、网络带宽小等原因，所引起的平台页面打开速度慢等风险。'
        ]
      },
    ]
  },
  {
    title: '九、【用户行为规范】',
    content: [
      {
        subtitle: '<span>9.1 您在使用本服务时须遵守法律法规，不得制作、复制、发布、传播含有下列内容的信息或利用本服务从事相关行为，也不得为制作、复制、发布、传播含有下列内容的信息或从事相关行为提供便利：</span>',
        text: [
          '<span>（1）反对宪法所确定的基本原则；</span>',
          '<span>（2）危害国家安全统一、泄露国家秘密、颠覆国家政权、破坏国家统一；</span>',
          '<span>（3）损害国家荣誉和利益的；</span>',
          '<span>（4）煽动民族仇恨、民族歧视、破坏民族团结的；</span>',
          '<span>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</span>',
          '<span>（6）散步谣言，扰乱社会秩序、破坏社会稳定、公序良俗、社会公德以及侮辱或者诽谤他人、侵害他人合法权益的；</span>',
          '<span>（7）散步淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span>',
          '<span>（8）违反法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；</span>',
          '<span>（9）相关法律法规或本协议、相关协议、规则所禁止的。</span>'
        ]
      },
      {
        subtitle: '<span>9.2 除非法律允许或腾讯书面许可，您使用本平台过程中不得从事下列行为：</span>',
        text: [
          '<span>（1）删除本服务及其副本上关于著作权的信息；</span>',
          '<span>（2）对本服务进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本服务的源代码；</span>',
          '<span>（3）对腾讯拥有知识产权的内容在本协议约定范围外进行使用、复制、修改、建立镜像站点等；</span>',
          '<span>（4）将注册账号、密码、密钥向他人提供，从而造成自己相关数据的错误或者丢失；</span>',
          '<span>（5）将本服务单独销售、分发或提供给他人使用或将本服务的任何内容以应用接口方式提供给他人或以本服务为基础创建、衍生产品应用或本服务相同、类似的服务；</span>',
          '<span>（6）对本服务或者本服务所涉软件运行过程中释放到任何终端内存中的数据、运行过程中客户端与服务器端的交互数据，以及运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经腾讯授权的第三方工具/服务接入软件和相关系统；</span>',
          '<span>（7）您不得通过任何方式或工具（如网络搜索/检索程序、抓取工具等），对本服务的任何内容进行检索或索引，或在未获得用户等权利人授权的情况下收集用户相关信息；</span>',
          '<span>（8）通过修改或伪造本服务运行中的指令、数据，增加、删减、变动本服务的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</span>',
          '<span>（9）通过非腾讯开发、授权的第三方软件、插件、外挂、系统，登录或使用本服务，或制作、发布、传播上述工具或通过非正常的方式（包括但不限于遍历抓取地图数据等）使用本服务；</span>',
          '<span>（10）自行或者授权他人、第三方软件对本服务及其组件、模块、数据进行干扰；</span>',
          '<span>（11）在本协议约定方式之外使用WeMap服务标志等腾讯的任何商业标识、腾讯产品标识近似的标识或模仿腾讯标识的内容；</span>',
          '<span>（12）以任何形式暗示与WeMap服务有关联或隶属关系、由WeMap服务赞助、经WeMap服务认可，或者可被合理推断为所编辑的内容由WeMap服务或腾讯创作、代表WeMap服务或腾讯的观点或意见；</span>',
          '<span>（13）将WeMap服务标志用于诋毁WeMap服务及腾讯旗下任何产品及服务；</span>',
          '<span>（14）在包含或显示色情内容、赌博、非法活动等非法信息或违反法律法规的网站上使用WeMap服务标志；</span>',
          '<span>（15）删除、修改、遮掩或以其他任何方式替换通过本服务提供给您的地图图片、搜索结果中可能包含的腾讯或WeMap服务相关的商业名称、商标、服务标记、徽标、域名或WeMap服务及其合作伙伴其他明显的标志;</span>',
          '<span>（16）其他未经腾讯明示授权的行为或违反法律法规、政策的行为。</span>',
        ]
      },
      {
        subtitle: '9.3【对自己行为负责】',
        text: [
          '您充分了解并同意，您必须为您使用本服务及注册帐号下的一切行为负责，包括您使用的登录账号，账号操作人员的合法授权及操作行为，提交资料的真实性、合法性，所发表的任何内容以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用本服务而引起的所有风险，包括因对本服务的正确性、完整性或实用性的依赖而产生的风险。腾讯无法且不会对因前述风险而导致的任何损失或损害承担责任，若因您的原因给腾讯造成任何损失，腾讯有权立即停止您的使用，并要求您承担相关法律责任。',
        ]
      },
      {
        subtitle: '9.4 【违约处理】',
        text: [
          '如果腾讯发现或收到他人举报或投诉您违反本协议约定的，腾讯有权视您的行为性质，采取包括但不限于暂停或终止本服务、追究法律责任等措施。',
          '您理解并同意，腾讯有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。',
          '您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；腾讯因此遭受损失的，您也应当一并赔偿。',
        ]
      },
    ]
  },
  {
    title: '十、【知识产权声明】',
    content: [
      {
        subtitle: '10.1 腾讯是本服务以及通过本服务所产生的任何数据的知识产权权利人。本服务以及通过本服务所产生的数据或产品的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本平台相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，腾讯享有上述知识产权，但相关权利人依照另行签署的协议应享有的权利除外。',
        text: []
      },
      {
        subtitle: '10.2 您知悉您仅拥有本协议约定合法使用本服务的权利。如您在应用的开发、制作中，经腾讯书面许可后使用了腾讯的知识产权，则所涉及内容的所有权及知识产权仍归属于腾讯所有，并且腾讯有权以自己的名义就已经开发完成的内容独立进行相关权利归属的保护申请。',
        text: []
      },
      {
        subtitle: '10.3 本服务所涉及的运营数据的全部权利，均归属腾讯所有，未经腾讯事先书面同意，您不得为任何目的使用。',
        text: []
      },
      {
        subtitle: '10.4 未经腾讯或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。',
        text: []
      },
      {
        subtitle: '10.5 您同意在此授予腾讯一项世界范围内的、免许可费的、不可转让的、非排他性的使用许可，许可腾讯可以在本条款期限内使用您的标志，以对外宣传或公布您正在使用本服务（例如在演示文稿、营销材料、客户名单、财务报告和网站列表（包括指向您网站的链接）中使用您的标志。您向腾讯陈述和保证，您拥有授予上述许可的全部权利、权力和必要法律授权。',
        text: []
      },
      {
        subtitle: '10.6 您同意并授权腾讯基于提供本服务必要性获取相应的数据，不论是您主动提供的还是使用本服务过程中获取的数据。您使用本服务，即您承诺并保证向使用您产品（网页或应用程序）的用户披露有关数据使用情形，并确保是在用户知情、并授权允许的情况下使用腾讯提供本服务获取用户位置数据（如涉及）等必要数据以提供相关服务。',
        text: []
      },
      {
        subtitle: '10.7 腾讯不允许任何人未经用户的明确同意而以任何手段收集、存储、出售或者传播用户的个人信息。一经发现您实施上述行为，腾讯有权立即终止向您提供本服务。',
        text: []
      }
    ]
  },
  {
    title: '十一、【终端安全责任】',
    content: [
      {
        subtitle: '11.1 您理解并同意，本服务同大多数互联网平台一样，可能会受多种因素影响（包括但不限于用户原因、网络服务质量、社会环境等）；也可能会受各种安全问题的侵扰（包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁您的终端设备信息和数据的安全，继而影响本平台的正常使用等）。因此，您应加强信息安全及用户信息的保护意识，注意密码保护，以免遭受损失。',
        text: []
      },
      {
        subtitle: '11.2 您不得制作、发布、使用、传播用于窃取手机号、QQ、微信、微信公众号及他人个人信息、财产的恶意程序。',
        text: []
      },
      {
        subtitle: '<span>11.3 维护本服务安全与正常使用是腾讯和您的共同责任，腾讯将按照行业标准合理审慎地采取必要技术措施保护您的终端设备信息和数据安全，但是您承认和同意腾讯不能就此提供任何保证。</span>',
        text: []
      },
      {
        subtitle: '<span>11.4 在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意腾讯有关防范诈骗犯罪的提示。</span>',
        text: []
      }
    ]
  },
  {
    title: '十二、【不可抗力及其他免责事由】',
    content: [
      {
        subtitle: '<span>12.1 您理解并同意，在使用本服务的过程中可能会遇到以下情况使服务发生中断。出现下述情况时，腾讯及时与相关单位配合进行修复，争取及时进行处理，但是由此给您造成的损失腾讯在法律允许的范围内免责。</span>',
        text: [
          '<span>（1）不可抗力，包括但不限于自然灾害、政府行为、政策颁布调整、法律法规颁布调整、罢工、动乱；</span>',
          '<span>（2）网络安全事故，受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</span>',
          '<span>（3）基础运营商原因，包括但不限于电信部门技术调整、电信/电力线路被他人破坏、电信/电力部门对电信网络/电力资源进；</span>',
          '<span>（4）其他非腾讯过错、腾讯无法控制或合理预见的情形。</span>',
        ]
      },
      {
        subtitle: '<span>12.2 腾讯对本服务不提供任何明示或默示的担保或保证，包含但不限于商业适售性、特定目的的适用性及不侵权等担保或保证。</span>',
        text: []
      },
      {
        subtitle: '<span>12.3 除上述12.2款外，腾讯不就以下各项向您作出明示或默示的担保或保证:</span>',
        text: [
          '<span>（1）本服务将符合您实际或特定的需求；</span>',
          '<span>（2）本服务将不受干扰、无中断、及时、安全、稳定、完整或没有错误；</span>',
          '<span>（3）使用本服务获得的结果或任何资料将是正确或可靠的；</span>',
          '<span>（4）经本服务购买或获得的任何产品、服务、资讯或其它信息符合您的期望；</span>',
          '<span>（5）本服务中的任何错误、问题或缺陷都将得到更正；</span>',
          '<span>（6）为向您提供便利而设置的外部链接的准确性、稳定性和完整性。</span>'
        ]
      },
      {
        subtitle: '<span>12.4 在任何情况下，腾讯均不对您任何间接性、偶然性、特殊性的损害和损失，包括但不限于可得利益损失、相对方向第三方支付的费用、任何商誉或业务声誉损失等承担责任（即使该方已经被告知该等损失的可能性）。</span>',
        text: []
      }
    ]
  },
  {
    title: '十三、【其他】',
    content: [
      {
        subtitle: '<span>13.1 您使用本服务即视为您已阅读并同意受本协议的约束。腾讯有权在必要时修改本协议条款。您可以在使用本服务前查阅相关协议条款。本协议条款变更后，如果您继续使用本服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本服务。</span>',
        text: []
      },
      {
        subtitle: '<span>13.2 本协议签订地为中华人民共和国深圳市南山区。</span>',
        text: []
      },
      {
        subtitle: '<span>13.3 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</span>',
        text: []
      },
      {
        subtitle: '<span>13.4 若您和腾讯之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</span>',
        text: []
      },
      {
        subtitle: '13.5 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。',
        text: []
      },
      {
        subtitle: '13.6 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。（正文完）',
        text: []
      }
    ]
  }
];

export default clauseList;
