import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueJsonp from 'vue-jsonp';

import TlbsUI from '@tencent/tlbs-ui';
import Clipboard from 'clipboard';
import App from './App.vue';

import router from './router';
import '@tencent/tlbs-ui/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { beaconReport } from './utils/beacon';
// import 'swiper/css/swiper.css';
import 'swiper/dist/css/swiper.css';

Vue.prototype.$clipboard = Clipboard;
Vue.prototype.$beacon = beaconReport;
Vue.use(VueJsonp);
Vue.use(TlbsUI);
Vue.use(VueAwesomeSwiper);


Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
