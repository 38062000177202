<template>
  <div class="product-base-container">
    <banner title="WeMap Base" subtitle="一站式时空数据管理、分析与服务发布平台" background-img="https://industry.map.qq.com/wemap/wemap-home/image/product/base/banner.png" />
    <advantage-list :list="advantageList"></advantage-list>
    <div class="animation-wrapper">
      <div class="ability-container dark-mode">
        <div class="container-title">我们的能力</div>
        <ability-list
          :info="storeInfo"
        />
      </div>

      <div class="ability-container light-mode">
        <ability-list
          :info="threeInfo"
          theme="light"
          align="right"
        />
      </div>

      <div class="ability-container dark-mode">
        <ability-list
          :info="searchInfo"
        />
      </div>

      <div class="ability-container light-mode">
        <ability-list
          :info="serverInfo"
          theme="light"
          align="right"
        />
      </div>

      <div class="ability-container dark-mode">
        <ability-list
          :info="spaceInfo"
        />
      </div>

      <div class="ability-container light-mode">
        <ability-list
          :info="dataInfo"
          theme="light"
          align="right"
        />
      </div>

      <div class="ability-container dark-mode">
        <ability-list
          :info="mapInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '../components/product-banner';
import AdvantageList from '../components/advantages-list';
import AbilityList from '../components/ability-list';
import { animation } from '@/utils';

export default {
  name: 'ProductBase',
  components: {
    Banner,
    AdvantageList,
    AbilityList,
  },
  data() {
    return {
      advantageList: [
        { name: '基于高精数据的GIS平台', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/base/advantage1.png' },
        { name: '高性能三维服务能力', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/base/advantage2.png' },
        { name: '兼容主流的GIS平台', img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/base/advantage3.png' },
      ],
      storeInfo: {
        title: 'GB-PB级的海量异构数据存储与管理',
        desc: '基于腾讯强大的云计算能力，针对海量异构时空数据数据如矢量、遥感影像、高精数据、倾斜摄影、BIM、LiDAR、实时流、互联网PB级时空轨迹数据的分布式高效存储、高性能时空索引、高并发数据的存储与管理。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/base/base-img1.png',
      },
      threeInfo: {
        title: '多源数据快速上图和编辑',
        desc: '基于存储的各类数据，实现矢量、影像、三维、高精数据的快速上图浏览及展示，以及支持矢量和三维数据的空间几何和属性信息的编辑。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/base/base-img2.png',
      },
      searchInfo: {
        title: '数据高效查询和检索',
        desc: '为用户提供高效的数据查询和检索能力，支持关键字检索、属性查询、周边查询、线/矩形/多边形查询，以及空间和属性的组合查询，并支持对查询结果进行再次筛选和排序。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/base/base-img3.png',
      },
      serverInfo: {
        title: '标准服务发布与注册',
        desc: '支持将二维、三维、影像等多源数据发布为各种类型的标准服务，包括REST服务、OGC标准服务、MVT服务、三维服务、实时流服务等，并支持第三方平台发布的标准OGC服务的注册、管理及上图。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/base/base-img4.png',
      },
      spaceInfo: {
        title: '丰富的空间分析与计算',
        desc: '提供丰富的空间分析能力，包括缓冲区分析、叠加分析、空间关系判断、裁剪、空间统计等二维分析，以及里程桩转换、动态路段生成等基于高精数据的分析。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/base/base-img5.png',
      },
      dataInfo: {
        title: '数据聚合与分析',
        desc: '提供多种数据聚合策略，支撑千万级数据的快速上图和聚合计算，并保证多级地图流畅渲染。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/base/base-img6.png',
      },
      mapInfo: {
        title: '地图制作与分享',
        desc: '提供地图创建、图层管理、专题图制作、地图分享与打印输出等地图制作与分享能力，支持热力图、唯一值、分级符号、分级颜色等多种专题图制作。',
        img: 'https://industry.map.qq.com/wemap/wemap-home/image/product/base/base-img7.png',
      },
    };
  },
  mounted() {
    animation.init();
  },
  destroyed() {
    animation.destroy();
  },
};
</script>

<style lang="less" scoped>
.product-base-container {
  .ability-container {
    overflow: hidden;
    &.dark-mode {
      color: @--color-text;
      background-color: @--color-light;
    }
    &.light-mode {
      color: @--color-text;
      background-image: radial-gradient(24.4% 54.06% at 26.06% 50%, #FFFFFF 1.15%, rgba(0, 0, 0, 0.0001) 100%);
    }
    .container-title {
      .title-text();
      font-family: @--font-tencent;
      text-align: center;
      margin-top: 80px;
    }
  }
}
</style>
