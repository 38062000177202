<template>
  <div
    class="ability-list"
    :class="[theme === 'gray' ? 'gray-theme' : theme === 'dark' ? 'dark-theme' :'light-theme']"
  >
    <div
      class="ability-container"
      :class="{ 'is-right': align === 'right' }"
    >
      <div class="ability-content">
        <div class="ability-title">{{info.title}}</div>
        <div class="ability-desc" v-if="info.desc">{{info.desc}}</div>
        <div v-if="Array.isArray(info.detail)" class="ability-detail">
          <div class="detail-item" v-for="(single, idx) in info.detail" :key="idx">
            <div class="detail-subtitle" v-if="single.subtitle">{{single.subtitle}}</div>
            <div class="detail-content">{{single.content}}</div>
          </div>
        </div>
        <div class="list-container" v-if="list.length" @mouseover="handleHover">
          <div
            class="ability-item"
            v-for="(item, index) in list"
            :key="index"
            :data-index="index"
            :class="{ 'is-active': index === active }"
          >
            <div class="ability-name" :data-index="index">{{item.name}}</div>
            <div
              v-if="item.tip && index === active"
              class="ability-tip"
              :data-index="index"
            >
              {{item.tip}}
            </div>
          </div>
        </div>
      </div>
      <video
        v-if="info.video"
        class="ability-video"
        webkit-playsinline="true"
        preload="metadata"
        playsinline="true"
        :poster="info.poster"
        :src="info.video"
        muted
        autoplay
        loop
        type="video/mp4"
        width="600px"
      >
      </video>
    </div>
    <!-- <img v-if="currentImg" :src="currentImg" class="ability-img" :id="`${_uid}-img`" > -->
    <div v-if="list.length">
      <img v-for="(item, index) in list" :key="index" :src="item.img" v-show="active === index" alt="" class="ability-img fade-in" />
    </div>
    <div v-else-if="info.img">
      <img :src="info.img" alt="" class="ability-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbilityList',
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          title: '',
          desc: '',
        };
      },
    },
    list: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: 'gray',
    },
    align: {
      type: String,
      default: 'left',
    }
  },
  data() {
    return {
      active: 0,
    };
  },
  watch: {
    // active() {
    //   const img = document.getElementById(`${this._uid}-img`);
    //   if (!img) return;
    //   this.hideElement(img);
    //   setTimeout(() => {
    //     this.showElement(img);
    //   }, 0);
    // }
  },
  computed: {
    currentImg() {
      return this.list.length > 0 ? this.list[this.active].img : this.info.img;
    },
  },
  methods: {
    handleHover(event) {
      const { target } = event;
      this.active = +target.dataset.index;
    },
    // hideElement(dom) {
    //   if (!dom) return;
    //   dom.classList.remove('fade-in');
    //   dom.style.opacity = 0;
    // },
    // showElement(dom) {
    //   if (!dom) return;
    //   dom.classList.add('fade-in');
    //   dom.style.opacity = 1;
    // },
  },
};
</script>

<style lang="less" scoped>
.ability-list {
  .fade-in {
    animation: fade-in .5s ease-in-out backwards;
  }
  height: 600px;
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .ability-container {
    width: @--content-width;
    box-sizing: border-box;
    position: absolute;
    .absolute-center();
    display: flex;
    align-items: center;
    &.is-right {
      flex-direction: row-reverse;
      .ability-content{
        box-sizing: border-box;
        padding-left: 80px;
      }
    }
    .ability-content {
      width: 600px;
      .ability-title {
        .sub-title-text();
        font-weight: 600;
        margin-bottom: 16px;
      }
      .ability-desc {
        .base-text();
        margin-bottom: 32px;
        width: 520px;
      }
      .ability-detail {
        width: 520px;
        margin-top: 32px;
        .detail-item {
          margin-bottom: 16px;
          &:last-of-type {
            margin-bottom: 0;
          }
          .detail-subtitle {
            .base-text();
            margin-bottom: 8px;
          }
          .detail-content {
            .base-text();
          }
        }
      }
      .list-container {
        width: 520px;
        .ability-item {
          // cursor: pointer;
          padding: 16px 24px;

          .ability-name {
            .para-text();
          }
          .ability-tip {
            overflow: hidden;
            animation: fade-in 400ms ease-in-out backwards;
            .base-text();
            margin-top: 8px;
          }
        }
      }
    }
    .ability-video {
      border-radius: 8px;
    }
  }

  &.gray-theme {
    color: @--color-text;
    .ability-desc {
      color: @--color-tip;
    }
    .ability-detail .detail-item {
      .detail-content {
        color: @--color-tip;
      }
    }
    .ability-container .list-container .ability-item {
      border-left: 2px solid @--color-border;
      .ability-name {
        color: @--color-text;
      }
      &.is-active {
        border-left-color: @--color-text;
        .ability-name {
          color: @--color-text;
          font-weight: 500;
        }
        .ability-tip {
          color: @--color-subtext;
        }
      }
    }
  }
  &.dark-theme {
    color: @--color-white;
    .ability-desc {
      color: fade(@--color-white, 60%);
    }
    .ability-detail .detail-item {
      .detail-content {
        color: fade(@--color-white, 80%);
      }
    }
    .ability-container .list-container .ability-item {
      border-left: 2px solid fade(#E6EAF4, 20%);
      .ability-name {
        color: fade(@--color-white, 80%);
      }
      &.is-active {
        border-left-color: @--color-white;
        background-color: fade(@--color-white, 20%);
        .ability-name {
          color: @--color-white;
          font-weight: 500;
        }
        .ability-tip {
          color: fade(@--color-white, 80%);
        }
      }
    }
  }
  &.light-theme {
    color: @--color-text;
    .ability-desc {
      color: @--color-tip;
    }
    .ability-detail .detail-item {
      .detail-content {
        color: @--color-tip;
      }
    }
    .ability-container .list-container .ability-item {
      border-left: 2px solid @--color-border;
      &.is-active {
        border-left-color: @--color-text;
        background: linear-gradient(90deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 99%) #F5F7FC;
        .ability-name {
          font-weight: 500;
        }
        .ability-tip {
          color: @--color-subtext;
        }
      }
    }
  }
  .ability-img {
    // position: relative;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 1600px;
    height: 600px;
  }
}
</style>
