<template>
  <div class="logo-list">
    <div v-for="j in 4" :key="j" >
      <img
        v-for="i in 5"
        :key="j*5+i"
        class="logo-tag"
        :src="list[(j-1)*5+i-1]"
        width="224px"
        height="88px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoList',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.logo-list {
  width: @--content-width;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  .logo-tag {
    margin-top: 20px;
    margin-right: 20px;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      margin-top: 0px;
    }
    &:nth-child(5n) {
      margin-right: 0px;
    }
  }
}
</style>
