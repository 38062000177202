<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Solution',
};
</script>

<style lang="less">
.solution-page-products {
  .sub-template();
}
.area-title {
  .sub-title();
}
</style>
