<template>
  <div class="wemap-home">
    <div>
      <!-- banner区域 -->
      <div class="banner-container">
        <!-- 轮播 -->
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide>
            <div class="banner-content">
              <div class="content-area banner-area-online">
                <div class="icon-wrapper">
                  <img src="https://industry.map.qq.com/wemap/wemap-home/image/index/online_icon.png" />
                </div>
                <h1 class="banner-title">WeMap</h1>
                <h1 class="banner-title">腾讯地图产业版</h1>
                <p class="banner-text">
                  一站式空间数据接入、空间分析及低代码应用发布
                </p>
                <div class="btn-wrapper">
                  <button class="banner-btn" @click="onClickUse">
                    立即使用
                  </button>
                  <button class="banner-btn banner-btn-video" @click="onClickVideo" @mouseenter="onMouseEnter"
                    @mouseleave="onMouseLeave">
                    <img :src="imageUrl" />
                    视频演示
                  </button>
                </div>
              </div>
              <video class="banner-video" :class="{ 'banner-video-min': shouldSetMinWidth }" webkit-playsinline="true"
                preload="metadata" playsinline="true"
                src="https://industry.map.qq.com/wemap/wemap-home/static/wemap-online.mp4" muted="muted"
                autoplay="autoplay" loop="loop" type="video/mp4"></video>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="banner-content">
              <div class="content-area">
                <h1 class="banner-title">WeMap</h1>
                <h1 class="banner-title">腾讯地图产业版</h1>
                <p class="banner-text">
                  腾讯面向智慧产业的数字地图底座，产品体系重磅升级
                </p>
                <div class="btn-wrapper">
                  <button class="banner-btn" @click="onClickUse">
                    立即使用
                  </button>
                  <button class="banner-btn banner-btn-video" @click="onClickVideo" @mouseenter="onMouseEnter"
                    @mouseleave="onMouseLeave">
                    <img :src="imageUrl" />
                    视频演示
                  </button>
                </div>
              </div>
              <video class="banner-video" :class="{ 'banner-video-min': shouldSetMinWidth }" webkit-playsinline="true"
                preload="metadata" playsinline="true"
                src="https://industry.map.qq.com/wemap/wemap-home/static/wemap-pre.mp4" muted="muted" autoplay="autoplay"
                loop="loop" type="video/mp4" width="2400px" height="650px"></video>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <!-- 公告中心 -->
        <div class="announcement-center-container" v-if="newsInfo.name">
          <div class="announcenment-center-content">
            <div class="center-title">公告中心</div>
            <a class="announcement-title" :href="isUrl(newsInfo.path_map)
              ? newsInfo.path_map
              : `${newsPath}/${newsInfo.path_map}`
              " target="_blank" @click="$beacon('click_home_news', {
    link: isUrl(newsInfo.path_map)
      ? newsInfo.path_map
      : `${newsPath}/${newsInfo.path_map}`
  })">
              {{ newsInfo.name }}
            </a>
            <a class="center-link" :href="`${newsPath}/`" target="_blank"
              @click="$beacon('click_home_news', { link: `${newsPath}/` })">
              查看更多公告<img src="https://industry.map.qq.com/wemap/wemap-home/image/index/icon_arrow_right@2x.png"
                class="icon-arrow" />
            </a>
          </div>
        </div>
      </div>
      <!-- 产品体系架构模块 -->
      <div class="product-architecture-container">
        <div class="area-title text-wrapper">
          构建面向产业互联网的<br />可感知、可计算、可应用的数字地图底座
        </div>
        <div class="img-wrapper">
          <img src="https://industry.map.qq.com/wemap/wemap-home/image/index/framework.png" />
        </div>
      </div>
      <!-- 产品体系 -->
      <div class="product-system-container">
        <div class="area-title">核心产品体系</div>
        <tab-gallery :list="productList"></tab-gallery>
      </div>
      <!-- 核心优势 -->
      <div class="key-advantage-container">
        <div class="area-title">核心优势</div>
        <div class="key-advantage-content">
          <div class="advantage-item" v-for="(advantage, index) in advantageList" :key="index">
            <img :src="advantage.icon" class="item-icon" />
            <span class="item-name">{{ advantage.name }}</span>
          </div>
        </div>
      </div>
      <!-- 解决方案 -->
      <div class="solution-container">
        <div class="area-title">全面、专业、智能的解决方案</div>
        <tab-gallery :list="solutionList" mode="solution"></tab-gallery>
      </div>
      <!-- 行业合作 -->
      <div class="business-cooperation-container">
        <div class="area-title">合作伙伴</div>
        <div class="company-list">
          <div v-for="i in 4" :key="i">
            <img v-for="j in 5" :key="i * 5 + j" :src="getCompanyImage(i, j)" class="company-item" />
          </div>
        </div>
      </div>
    </div>
    <video-dialog :visible.sync="isShowVideo" :video="video"></video-dialog>
  </div>
</template>

<script>
import TabGallery from './components/tab-gallery';
import { MobileDevices } from './constants';
import VideoDialog from './components/video-dialog';
import httpService from '@/utils/http';
import { isUrl } from '@/utils/index';
import { getCookie } from '@/utils/beacon';
import ScrollReveal from 'scrollreveal';

const cdnUrl = 'https://industry.map.qq.com/wemap/wemap-home/image/index/companies/company_'; // 静态资源cdn地址

const newsPath = '/news/wemap';

const animation = {
  advantage: null,
  company: null,
  init() {
    this.advantage = document.getElementsByClassName('key-advantage-content');
    console.log('this.advantage', this.advantages);
    this.company = document.getElementsByClassName('company-list');
    this.bind();
  },
  bind() {
    this.conf(this.advantage, '20px', 500);
    this.conf(this.company, '20px', 500);
  },
  conf(ele, distance, speed) {
    [ele] = ele;
    const eleLenght = ele.childNodes.length;
    function animationFn(i) {
      return {
        reset: false,
        distance,
        duration: speed, // 动画持续时间
        delay: (i + 1) * 130, // 延迟
        opacity: 0, // 初始透明度: 0.2到1的效果
        easing: 'ease-in-out', // 动画效果:缓动'ease', 'ease-in-out'，'linear'...
      };
    }
    for (let i = 0; i < eleLenght; i++) {
      // eslint-disable-next-line new-cap
      ScrollReveal().reveal(ele.childNodes[i], animationFn(i));
    }
  },
  destroy() {
    // eslint-disable-next-line new-cap
    ScrollReveal().destroy();
  },
};

export default {
  name: 'home',
  components: {
    TabGallery,
    VideoDialog,
  },
  data() {
    return {
      swiperOptions: {
        simulateTouch: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 8000,
          disableOnInteraction: true,
        },
        loop: true,
      },
      isUrl,
      newsPath,
      newsInfo: {},
      advantageList: [
        {
          name: '数据鲜活完备',
          icon: 'https://industry.map.qq.com/wemap/wemap-home/image/index/icon_lbs.png',
        },
        {
          name: '引擎能力丰富',
          icon: 'https://industry.map.qq.com/wemap/wemap-home/image/index/icon_compute.png',
        },
        {
          name: '高品质低成本',
          icon: 'https://industry.map.qq.com/wemap/wemap-home/image/index/icon_data3.png',
        },
        {
          name: '高开放易被集成',
          icon: 'https://industry.map.qq.com/wemap/wemap-home/image/index/icon_data4.png',
        },
        {
          name: '低代码易组装',
          icon: 'https://industry.map.qq.com/wemap/wemap-home/image/index/icon_data5_new.png',
        },
      ],
      productList: [
        {
          name: 'Builder',
          title: '地图应用构建平台',
          desc: [
            '支持用户创建发布PC、H5、小程序类型的应用。具备空间数据快速上图、多端应用快速发布、应用数据管理以及提供便捷的二次开发能力，以满足客户的多样化定制需求。',
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/builder.jpg',
          link: '/products/builder',
        },
        {
          name: 'Base',
          title: '空间数据管理中台',
          desc: [
            '基于腾讯强大的云计算能力，针对海量异构时空数据，提供高效的分布式存储、管理和查询等能力，支持空间数据发布和空间分析，为构建行业一张图提供数据管理支撑。',
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/base.jpg',
          link: '/products/base',
        },
        {
          name: 'Services',
          title: '地图服务',
          desc: [
            '基于鲜活的互联网地图数据和成熟的引擎，提供可多网部署的高性能、高可用的地图服务，为行业一张图建设提供底图、检索、路线规划等服务能力。',
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/services.jpg',
          link: '/products/service',
        },
        // {
        //   name: 'Vis',
        //   title: '地图可视化',
        //   desc: [
        //     '基于腾讯强大的渲染引擎能力，提供高品质、高性能、二三维一体化的渲染引擎，通过统一的 B/S 开放架构支撑灵活的二次开发，实现多行业、 多场景的一张图应用。',
        //   ],
        //   img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/vis.png',
        //   link: '/products/vis',
        // },
        {
          name: 'Mobile',
          title: '移动端地图',
          desc: [
            '依托腾讯丰富的地图数据，在专网环境下面向移动端设备提供地图展示和地图导航能力，支撑行业在移动端场景下的地图应用。',
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/mobile.jpg',
          link: '/products/mobile',
        },
        {
          name: 'Data',
          title: '地图数据',
          desc: [
            '依托腾讯专业化的数据生产体系和丰富的数据生态，提供标/高精数据采集和数据生产服务，为行业一张图提供坚实的数据基础。',
          ],
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/data.jpg',
          link: '/products/data',
        },
      ],
      solutionList: [
        {
          name: '智慧交通',
          desc: '基于WeMap对人、车、路、地、物的时空感知、数据管理、智能分析和可视化能力，构建交通业务实时态势感知、历史变化溯源、时空模拟推演的全流程解决方案，从而解决交通行业在规划、建设、管理、运营和服务方面对空间位置服务需求的问题。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/transport.jpg',
          link: '/solution/transport',
        },
        {
          name: '城市治理',
          desc: '基于腾讯WeMap丰富自研地图产品矩阵和鲜活的时空大数据 ，结合政府拥有的空间数据和业务数据，面向城市治理提供强大的分析处理及地图可视化能力，实现城市运行的“一图统揽、按图决策、依图施策”。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/city.jpg',
          link: '/solution/city',
        },
        {
          name: '智慧应急',
          desc: '基于腾讯WeMap丰富自研地图产品矩阵和鲜活的时空大数据，提供城市应急过程中全场景智能感知、可视化智能分析、高效应急处理指挥等服务，实现应急场景快速反应、科学决策和统一指挥。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/emergency.jpg',
          link: '/solution/emergency',
        },
        {
          name: '智慧文旅',
          desc: '基于腾讯WeMap丰富自研地图产品矩阵和鲜活的时空大数据及微信小程序原生能力，为景区提供景区可视化管控平台、智慧导览、位置大数据、专网地图等多维度的智能产品，帮助景区提升运管能力，优化游客体验。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/tourism.jpg',
          link: '/solution/tourism',
        },
        {
          name: '智慧零售',
          desc: '基于腾讯鲜活的时空大数据和WeMap产品能力，通过对消费者和市场的快速洞察，实现以消费者为中心，以数据为驱动，重构人货场经营策略，提升运营效率，节约成本，实现业绩增长，助力新零售。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/retail.jpg',
          link: '/solution/retail',
        },
        {
          name: '智慧地产',
          desc: '基于腾讯鲜活的时空大数据和WeMap产品能力，为地产行业提供从投拓拿地到销售运营的业务全生命周期数字化解决方案，助力企业管理决策提效、服务能力提升。',
          img: 'https://industry.map.qq.com/wemap/wemap-home/image/index/estate.jpg',
          link: '/solution/estate',
        },
      ],
      imageUrl: 'https://industry.map.qq.com/wemap/wemap-home/image/index/vedio_play@2x.png',
      hoverImageUrl: 'https://industry.map.qq.com/wemap/wemap-home/image/index/vedio_play_color@4x.png',
      video: 'https://industry.map.qq.com/wemap/wemap-home/static/wemap.mp4',
      isShowVideo: false,
      shouldSetMinWidth: false,
    };
  },
  computed: {
    companyImages() { // 图片地址缓存在 companyImages 计算属性中
      const images = {};
      let imgIdx = 1;
      for (let i = 1; i <= 4; i++) {
        for (let j = 1; j <= 5; j++) {
          images[`${i}-${j}`] = `${cdnUrl}${imgIdx}.png`; // 键为 i-j，值为图片地址
          imgIdx += 1;
        }
      }
      return images;
    }
  },
  mounted() {
    const ua = window.navigator.userAgent;
    const isMobile = MobileDevices.some((device) => ua.includes(device));
    if (!isMobile) {
      this.getNewsData();
    }
    animation.init();
    this.checkWidth(); // 页面加载时检测宽度
    window.addEventListener('resize', this.checkWidth); // 监听窗口大小变化
    this.initSwiter();
  },
  destroyed() {
    animation.destroy();
    window.removeEventListener('resize', this.checkWidth); // 移除监听
  },
  methods: {
    initSwiter() {
      const that = this;
      setTimeout(() => {
        document.querySelectorAll('.swiper-pagination-bullets').forEach((item) => {
          item && item.addEventListener('click', (e) => {
            const children = Array.from(document.querySelectorAll('.swiper-pagination-bullet'));
            // 获取点击的元素在子元素列表中的索引
            const index = children.indexOf(e.target);
            if (index !== -1) {
              that.$beacon('click_home_slider', {
                index: index + 1,
                website_type: 'wemap'
              });
            }
          });
        });
      });
    },
    onMouseEnter() {
      this.imageUrl = this.hoverImageUrl;
    },
    onMouseLeave() {
      this.imageUrl = 'https://industry.map.qq.com/wemap/wemap-home/image/index/vedio_play@2x.png';
    },
    getCompanyImage(i, j) {
      const key = `${i}-${j}`;
      return this.companyImages[key];
    },
    onClickLink(link) {
      link && this.$router.push(link);
    },
    async getNewsData() {
      try {
        const res = await httpService({
          url: '/api/site/category/listByMap',
          data: {
            // eslint-disable-next-line camelcase
            path_map: newsPath,
            // eslint-disable-next-line camelcase
            page_index: 1,
            // eslint-disable-next-line camelcase
            page_size: 10,
          },
        });
        if (res) {
          const { categorys } = res;
          if (Array.isArray(categorys) && categorys.length) {
            [this.newsInfo] = categorys;
          } else {
            console.error(res);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 立即使用
    onClickUse() {
      const loginType = parseInt(getCookie('login_type'), 10);
      if (loginType > 0) {
        window.open(`https://${window.location.host}/console/home`); // 注意不同环境的地址！
        return;
      } else {
        // 弹出登录框
        window.TLBS.component.login.init({ isSubAccountShow: true, url: 'https://wemap.map.qq.com', urlText: 'wemap', text: 'wemap' }); // 初始化时 传入回调函数地址
        window.TLBS.component.login.show();
      }
    },
    onClickVideo() {
      this.isShowVideo = true;
      const dom = document.getElementById('videoPlayer');
      if (dom) {
        dom.play();
      }
    },
    // 监听窗口宽度改变 确定是否设置video最小宽度
    checkWidth() {
      const width = window.innerWidth;
      console.log('window.innerWidth:', width);
      this.shouldSetMinWidth = width < 1200;
    }
  },
  beforeRouteEnter(to, from, next) {
    const ua = window.navigator.userAgent;
    const isMobile = MobileDevices.some((device) => ua.includes(device));
    if (isMobile) {
      window.location.replace('/mobile');
    } else {
      next();
    }
  },
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
